import { http } from 'services';
import { TYPE } from './constants';

export const List = ({ params }) => http.request.post('/api/v1/admin/blog/list/type-for-admin', {
    page: params.page,
    per_page: params.perPage,
    search: params.filter
  }, {
  params: {
    type: TYPE
  }
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/blog/${id}`);

export const Create = ({ values }) => http.request.post(`/api/v1/admin/blog/blog-for-our-service/${values.ourServiceId}`, {
    info: {
      uz: values.info.uz,
      oz: values.info.oz,
      ru: values.info.ru,
      en: values.info.en
    },
    reqDocs: {
      uz: values.reqDocs.uz,
      oz: values.reqDocs.oz,
      ru: values.reqDocs.ru,
      en: values.reqDocs.en
    },
    url: values.url,
    type: TYPE,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/blog/article/${id}`, {
    info: {
      uz: values.info.uz,
      oz: values.info.oz,
      ru: values.info.ru,
      en: values.info.en
    },
    reqDocs: {
      uz: values.reqDocs.uz,
      oz: values.reqDocs.oz,
      ru: values.reqDocs.ru,
      en: values.reqDocs.en
    },
    ourServiceId: values.ourServiceId,
    url: values.url,
    type: TYPE,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/blog/${id}`);