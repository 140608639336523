import React from 'react';
import { useTranslation } from "react-i18next";

import * as Icons from 'assets/icons';

import cls from './Dropdown.module.scss';

const Dropdown = ({ name, value, onClick, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => onClick && onClick()} className={cls.wrapper}>
      <span className={cls.name}>{t(name)}:</span>
      <strong className={cls.value}>{value || t('all')}</strong>
      {!!value && (
        <div
          onClick={e => {
            e.stopPropagation();
            onCancel && onCancel()
          }}
          className={cls.icon}
        >
          <Icons.Close />
        </div>
      )}
    </div>
  )
}

export default Dropdown;