import React from 'react';

import Icon from '../Icon';

import Details from './Details';

import classes from './Uploader.module.scss';

const Default = ({ accept, type, details, onSelect }) => {
  const title = {
    image: 'Требование к фото:',
    video: 'Требование к видео:',
    file: 'Требование к файлу:'
  };

  const icon = {
    image: 'CameraAdd',
    video: 'VideoAdd',
    file: 'DocumentAdd'
  };

  return (
    <label className={classes.trigger}>
      <div className={classes.content}>
        <div className={classes.title}>{title[type]}</div>
        <Details {...details} size={`до ${details.size}`} />
      </div>
      <div className={classes.upload}>
        <div className={classes.uploadIcon}>
          <Icon type="fluent" variant="filled" name={icon[type]} />
        </div>
        <div className={classes.uploadButton}>
          <input
            accept={accept && accept.join(',')}
            type="file"
            multiple={false}
            onChange={({ target: { files } }) => {
              const file = files && files[0];

              if (file) {
                onSelect(file);
              }
            }}
          />
          <span>Загрузить</span>
        </div>
      </div>
    </label>
  );
};

export default Default;
