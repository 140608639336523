import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import EditorBase from './Base/Editor';

const Editor = ({ title, name, required }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(title)} {...{ required }} />
      <EditorBase
        name={name}
        placeholder={t(`${title}_enter`)}
      />
    </>
  )
};

export default Editor;
