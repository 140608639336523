import React from 'react';

const NotificationBing = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M10 5.367v2.775"
      opacity=".4"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M10.017 1.667a5.548 5.548 0 0 0-5.55 5.55v1.75c0 .566-.234 1.416-.525 1.9l-1.059 1.766c-.65 1.092-.2 2.309 1 2.709a19.45 19.45 0 0 0 12.275 0 1.85 1.85 0 0 0 1-2.709L16.1 10.867c-.292-.484-.525-1.342-.525-1.9v-1.75c-.008-3.05-2.508-5.55-5.558-5.55Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M12.775 15.683A2.785 2.785 0 0 1 10 18.458a2.78 2.78 0 0 1-1.958-.816c-.5-.5-.817-1.2-.817-1.959"
      opacity=".4"
    />
  </svg>
);

export default NotificationBing;
