import get from 'lodash/get';

export const Profile = (item) => ({
  id: get(item, 'userId') || '',
  role: get(item, 'roleName') || '',
  permissions: get(item, 'authorities') || [],
  username: get(item, 'username') || '',
  firstName: get(item, 'firstName') || '',
  lastName: get(item, 'lastName') || '',
  middleName: get(item, 'middleName') || '',
  fullName: get(item, 'fullName') || ''
});

export const Token = (item) => ({
  token: get(item, 'data.access_token') || ''
});