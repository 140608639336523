import React, { useEffect } from "react";

import PaginationBase from "components/Pagination";

import cls from './Pagination.module.scss';

const Pagination = ({ total, current, currentItems, totalItems, params, setParams }) => {
  useEffect(() => {
    setParams({ ...params, page: current });
  }, [current, params, setParams]);

  return (
    <div className={cls.pagination}>
      <span className={cls.paginationText}>Yangiliklar:</span>

      <strong className={cls.paginationCount}>{currentItems} of  {totalItems}</strong>

      <PaginationBase
        total={total}
        current={current || 1}
        onChange={page => setParams({ ...params, page })}
      />
    </div>
  )
};

export default Pagination;