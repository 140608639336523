import React from 'react';

import { ROLES } from "modules/auth/constants";
import * as Fields from 'containers/Fields';

const Form = () => (
  <Fields.Select
    title='role_name'
    name="roleName"
    options={[
      { title: 'status_enum_ADMIN', value: ROLES.ADMIN },
      { title: 'status_enum_CONTENT_MANAGER', value: ROLES.CONTENT_MANAGER },
      { title: 'status_enum_CALL_MANAGER', value: ROLES.CALL_MANAGER },
      { title: 'status_enum_CLIENT', value: ROLES.CLIENT }
    ]}
  />
);

export default Form;