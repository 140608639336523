export const ENTITY = 'BANNER';

export const TYPE = {
  MAIN_BANNER: 'MAIN_BANNER',
  INNER_BANNER: 'INNER_BANNER'
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}
