import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/statistic/category/list-statistic-category', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const ListAll = () => http.request.get('/api/v1/admin/statistic/category/list');

export const Single = ({ id }) => http.request.get(`/api/v1/admin/statistic/category/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/statistic/category', {
    statisticName: {
      uz: values.statisticName.uz,
      oz: values.statisticName.oz,
      ru: values.statisticName.ru,
      en: values.statisticName.en
    },
    typeGraphic: values.typeGraphic,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/statistic/category/${id}`, {
    statisticName: {
      uz: values.statisticName.uz,
      oz: values.statisticName.oz,
      ru: values.statisticName.ru,
      en: values.statisticName.en
    },
    typeGraphic: values.typeGraphic,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/statistic/category/${id}`);