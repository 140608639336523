import React from 'react';
import cx from "classnames";

import SpinnerBase from 'components/Spinner';

import classes from './Spinner.module.scss';

const Spinner = ({ isLoading }) => (
  <div
    className={cx(
      classes.wrapper,
      isLoading && classes['wrapper--visible']
    )}
  >
    <SpinnerBase />
  </div>
);

export default Spinner;