import React, { useEffect, useRef, useState } from 'react';
import cx from "classnames";

import DropdownBase from "components/Dropdown";

import cls from './Dropdown.module.scss';

const Dropdown = ({ name, value, children, onCancel }) => {
  const childrenRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const wrapper = document.querySelector('.app');

    let cancel = false;

    wrapper?.addEventListener('click', (e) => {
      if (cancel) return;

      if (!childrenRef.current?.contains(e.target)) {
        setVisible(false);
      }
    });

    return () => {
      cancel = true;
    }
  }, []);

  return (
    <div className={cls.wrapper}>
      <DropdownBase
        {...{ name, value, onCancel }}
        onClick={() => setVisible(true)}
      />

      <div
        ref={childrenRef}
        className={cx(cls.content, visible && cls['content--show'])}
      >
        {children}
      </div>
    </div>
  )
}

export default Dropdown;