import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import CheckboxBase from "./Base/Checkbox";

const Checkbox = ({ title, name }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(title)} />
      <CheckboxBase name={name} />
    </>
  )
}

export default Checkbox;