import React from 'react';
import cx from "classnames";
import { ToastContainer } from "react-toastify";

import Sidebar from "./Sidebar";

import cls from './Main.module.scss';

const Main = ({ children }) => (
  <div className={cx(cls.wrapper, 'app')}>
    <Sidebar />

    <div className={cls.content}>
      <div className={cls.container}>
        {children}
      </div>
    </div>

    <ToastContainer autoClose={1500} />
  </div>
);

export default Main;