import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useUrlSearchParams } from "use-url-search-params";
import { toast } from "react-toastify";

import * as Hooks from "modules/call/hooks";
import * as Forms from "modules/call/forms";

import TopBar from "components/TopBar";
import Content from "components/Content";
import Title from "components/Title";
import Badge from "components/Badge";
import Empty from "components/Empty";
import Modal from "components/Modal";
import Spacer from "components/Spacer";
import Table from "containers/Table";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Pagination from "containers/Pagination";
import FilterList from "./components/FilterList";
import Form from "./components/Form";

const List = () => {
  const { t } = useTranslation();
  const [params, setParams] = useUrlSearchParams();
  const [selected, setSelected] = useState(null);

  const { items, meta, isFetched } = Hooks.useList({
    params: {
      page: params.page || 1,
      filter: [
        {
          key: 'name',
          operation: '=',
          value: params.name
        },
        {
          key: 'phone',
          operation: '=',
          value: params.phone
        },
        {
          key: 'callingStatus',
          operation: '=',
          value: params.callingStatus
        }
      ]
    }
  });

  return (
    <>
      <TopBar
        left={<Title text={t('call_title')} />}
      />

      <FilterList {...{ params, setParams }} />

      <Spacer size={20} />

      <Content>
        { !isFetched ? (
            <div style={{ position: 'relative', padding: 200 }}>
              <Spinner />
            </div>
          ) : !items.length ? (
            <Empty
              title={t('list_empty_here')}
              description={t('list_will_appear_soon')}
            />
          ) : (
            <>
              <Table
                items={items}
                columns={[
                  {
                    name: '№',
                    selector: row => row.id,
                    width: '80px'
                  },
                  {
                    name: t('name'),
                    selector: row => row.name,
                    width: '200px'
                  },
                  {
                    name: t('phone'),
                    selector: row => row.phone,
                    width: '300px'
                  },
                  {
                    name: t('date'),
                    selector: row => row.createdAt
                  },
                  {
                    name: t('status'),
                    selector: row => <Badge status={row.callingStatus} />
                  }
                ]}
                onEdit={item => setSelected(item)}
              />

              <Spacer size={20} />

              <Pagination
                current={meta.current}
                total={meta.totalPages}
                currentItems={items.length}
                totalItems={meta.totalItems}
                {...{ params, setParams }}
              />
            </>
          )
        }
      </Content>

      <Modal visible={!!selected} onCancel={() => setSelected(null)}>
        <Forms.Update
          id={selected?.id}
          values={{
            callingStatus: selected?.callingStatus
          }}
          onSuccess={() => {
            toast.success('successfully_updated');
            setSelected(null);
          }}
        >
          {({ isSubmitting }) => (
            <>
              <Form />

              <Spacer size={20} />

              <div style={{ display: 'flex', rowGap: '15px' }}>
                <Button onClick={() => setSelected(null)} variant="neutral" title={t('action_cancel')} block />
                <Button variant='success' title={t('action_save')} type='submit' block disabled={isSubmitting} />
              </div>
            </>
          )}
        </Forms.Update>
      </Modal>
    </>
  )
};

export default List;