import React from 'react';

const Chart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" opacity=".4">
      <path d="M7 10.74v3.2M12 9v6.68M17 10.74v3.2" />
    </g>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7Z"
    />
  </svg>
);

export default Chart;
