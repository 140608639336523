import React from 'react';
import { useField } from 'formik';

import { http } from 'services';

import * as Helpers from 'helpers';

import EditorBase from 'components/Editor';

const Editor = ({ name, ...props }) => {
  const [field, meta, helper] = useField({ name });
  const hasError = !!(meta.error && meta.touched);

  const imagesUploadHandler = (blobInfo, onSuccess, onError, progress) => {
    const formData = new FormData();

    formData.append('file', blobInfo.blob());

    http.request
      .post(`/api/v1/file/open-source/upload`, formData, {
        onUploadProgress: ({ total, loaded }) => {
          progress && progress((loaded / total) * 100);
        }
      })
      .then(({ data }) => {
        onSuccess(Helpers.File.Url(data?.data?.id));
      })
      .catch(err => {
        onError('Error occurred', { remove: true });
      });
  };

  return (
    <EditorBase
      {...field}
      {...props}
      {...{ imagesUploadHandler }}
      apiKey="yyjzfp7kyupznh3jipr6xmwifjadgp1qgugqzky4scokg3ld"
      id={field.name}
      value={field.value || ''}
      message={hasError ? meta.error : ''}
      state={hasError ? 'error' : 'default'}
      onChange={value => helper.setValue(value)}
    />
  );
};

export default Editor;
