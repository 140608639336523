export const ENTITY = 'STATISTIC';

export const COLORS = {
  RED: 'RED',
  PINK: 'PINK',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  PURPLE: 'PURPLE',
  WARNING: 'WARNING',
  TURQUOISE: 'TURQUOISE',
  LIGHTPURPLE: 'LIGHTPURPLE'
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}
