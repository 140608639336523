import React from 'react';
import cx from 'classnames';

import * as Helpers from 'helpers';

import Icon from "../Icon";
import Details from './Details';

import classes from './Uploader.module.scss';

const Preview = ({ name, type, photoId, extension, size, onView, onRemove }) => (
  <>
    <div className={classes.content}>
      <div className={classes.name}>{name}</div>
      <Details {...{ extension, size }} />
    </div>
    <div className={classes.preview}>
      <div className={classes.previewImage}>
        {type === 'image' ? (
          <img src={Helpers.Image.Url(photoId)} alt="image preview" />
        ) : (
          <div className={cx(classes['file-type'], classes[`file-type--${type}`])}>
            {type === 'video' && <Icon size={48} variant="filled" name="HeadphoneSoundWave" />}
            {type === 'file' && <Icon size={48} variant="filled" name="Document" />}
          </div>
        )}
      </div>
      <div className={classes.previewButtonList}>
        <div className={classes.previewButtonItem} onClick={() => onView()}>
          <Icon name="EyeShow" size={20} />
        </div>
        <div className={classes.previewButtonItem} onClick={() => onRemove()}>
          <Icon name="Delete" size={20} />
        </div>
      </div>
    </div>
  </>
);

export default Preview;
