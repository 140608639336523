import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/user-calling/list', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/user-calling/${id}`, {
    callingStatus: values.callingStatus
  }
);