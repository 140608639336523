import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/media-lib/list/admin/', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/media-lib/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/media-lib', {
    shortTitle: {
      uz: values.shortTitle.uz,
      oz: values.shortTitle.oz,
      ru: values.shortTitle.ru,
      en: values.shortTitle.en
    },
    about: {
      uz: values.about.uz,
      oz: values.about.oz,
      ru: values.about.ru,
      en: values.about.en
    },
    mediaLibType: values.mediaLibType,
    photoId: values.photoId,
    fileId: values.fileId,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/media-lib/${id}`, {
    shortTitle: {
      uz: values.shortTitle.uz,
      oz: values.shortTitle.oz,
      ru: values.shortTitle.ru,
      en: values.shortTitle.en
    },
    about: {
      uz: values.about.uz,
      oz: values.about.oz,
      ru: values.about.ru,
      en: values.about.en
    },
    mediaLibType: values.mediaLibType,
    photoId: values.photoId,
    fileId: values.fileId,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/media-lib/${id}`);