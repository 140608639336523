export const ENTITY = 'STATISTIC_CATEGORY';

export const TYPE = {
  PIE_CHART: 'PIE_CHART',
  BAR_CHART: 'BAR_CHART',
  SQUARE_CHART: 'SQUARE_CHART'
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}