import React from 'react';
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/ourService/hooks';
import { STATUS } from 'modules/ourService/constants';

import * as Filter from 'containers/Filter';
import Dropdown from "containers/Dropdown";

const FilterList = ({ params, setParams }) => {
  const { t, i18n } = useTranslation();

  const { items } = Hooks.useParentList();

  const STATUS_LIST = {
    ALL: t('all'),
    [STATUS.ACTIVE]: t('status_enum_ACTIVE'),
    [STATUS.INACTIVE]: t('status_enum_INACTIVE')
  };

  const setParamValue = (name, value) => {
    setParams({ ...params, [name]: value || undefined, page: undefined });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
      <Dropdown
        name='name'
        value={params.name || ''}
        onCancel={() => setParamValue('name', '')}
      >
        <Filter.Input
          value={params.name || ''}
          setValue={value => setParamValue('name', value)}
        />
      </Dropdown>

      <Dropdown
        name='status'
        value={params.status || ''}
        onCancel={() => setParamValue('status', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.ACTIVE, title: STATUS_LIST[STATUS.ACTIVE] },
            { value: STATUS.INACTIVE, title: STATUS_LIST[STATUS.INACTIVE] }
          ]}
          value={params.status || ''}
          setValue={value => setParamValue('status', value)}
        />
      </Dropdown>

      <Dropdown
        name='our_service_parent'
        value={params.parentId || ''}
        onCancel={() => setParamValue('parentId', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            ...items.map(item => ({ value: item.id, title: item.name[i18n.language]}))
          ]}
          value={params.parentId || ''}
          setValue={value => setParamValue('parentId', value)}
        />
      </Dropdown>
    </div>
  )
}

export default FilterList;