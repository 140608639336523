import React from 'react';
import { useTranslation } from "react-i18next";

import Icon from 'components/Icon';
import TableBase from 'components/Table';

import cls from './Table.module.scss';

const Table = ({ items, columns, onShow, onEdit, onDelete, ...props }) => {
  const { t } = useTranslation();

  return (
    <TableBase
      data={items}
      columns={[
        ...columns,
        {
          name: t('action'),
          cell: row => (
            <div className={cls.action}>
              {!!onShow && <Icon onClick={() => onShow && onShow(row)} name='EyeShow' size={24} />}
              {!!onEdit && <Icon onClick={() => onEdit && onEdit(row)} name='Edit' size={24} color='#E87129' />}
              {!!onDelete && <Icon onClick={() => onDelete && onDelete(row)} name='Delete' size={24} color='#F61E2E' />}
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]}
      { ...props }
    />
  )
}

export default Table;