import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import InputBase from "./Base/Input";

const Input = ({ title, type, name, required }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(title)} {...{ required }} />
      <InputBase
        name={name}
        type={type || 'text'}
        placeholder={`${title}_enter`}
      />
    </>
  )
}

export default Input;