import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Switch from "./Base/Switch";

const Status = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t('status')} />
      <Switch name='status' />
    </>
  )
}

export default Status;