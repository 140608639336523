import React from 'react';
import ReactPaginate from "react-paginate";

import Icon from 'components/Icon';

import cls from './Pagination.module.scss';

const Pagination = ({ total, current, onChange }) => (
   <ReactPaginate
      breakLabel="..."
      pageCount={total}
      pageRangeDisplayed={3}
      forcePage={current - 1}
      onPageChange={({ selected }) => onChange(selected + 1)}
      nextLabel={<Icon name='ChevronRight' size={20} />}
      previousLabel={<Icon name='ChevronLeft' size={20} />}
      containerClassName={cls.wrapper}
      pageClassName={cls.item}
      pageLinkClassName={cls.itemLink}
      activeClassName={cls.itemActive}
      activeLinkClassName={cls.itemActiveLink}
      previousClassName={cls.item}
      previousLinkClassName={cls.itemLink}
      nextClassName={cls.item}
      nextLinkClassName={cls.itemLink}
      disabledClassName={cls.itemDisabled}
   />
);

export default Pagination;