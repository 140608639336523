import get from 'lodash/get';
import { PAGE } from './constants';

export const InfoPages = (item) => ({
  info: {
    uz: get(item, 'info.uz') || '',
    oz: get(item, 'info.oz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || '',
  },
  reqDocs: {
    uz: get(item, 'reqDocs.uz') || '',
    oz: get(item, 'reqDocs.oz') || '',
    ru: get(item, 'reqDocs.ru') || '',
    en: get(item, 'reqDocs.en') || '',
  },
  pageName: get(item, 'pageName') || PAGE.ABOUT_US
});