import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/statistic/list-statistic', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/statistic/${id}`);

export const Create = ({ values }) => http.request.post(`/api/v1/admin/statistic/${values.categoryId}`, {
    title: {
      uz: values.title.uz,
      oz: values.title.oz,
      ru: values.title.ru,
      en: values.title.en
    },
    rating: values.rating,
    color: values.color,
    sort: values.sort,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/statistic/${id}`, {
    title: {
      uz: values.title.uz,
      oz: values.title.oz,
      ru: values.title.ru,
      en: values.title.en
    },
    categoryId: values.categoryId,
    rating: values.rating,
    color: values.color,
    sort: values.sort,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/statistic/${id}`);