import React from 'react';

const People = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.998 5.967a.505.505 0 0 0-.158 0 2.144 2.144 0 0 1-2.067-2.15c0-1.192.958-2.15 2.15-2.15a2.15 2.15 0 0 1 .075 4.3ZM14.14 12.033c1.141.192 2.4-.008 3.283-.6 1.175-.783 1.175-2.066 0-2.85-.892-.591-2.167-.791-3.308-.591M4.973 5.967a.505.505 0 0 1 .158 0 2.144 2.144 0 0 0 2.067-2.15c0-1.192-.958-2.15-2.15-2.15a2.15 2.15 0 0 0-.075 4.3ZM5.831 12.033c-1.141.192-2.4-.008-3.283-.6-1.175-.783-1.175-2.066 0-2.85.892-.591 2.167-.791 3.308-.591"
      opacity=".4"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 12.192a.5.5 0 0 0-.158 0 2.144 2.144 0 0 1-2.067-2.15c0-1.192.958-2.15 2.15-2.15a2.15 2.15 0 0 1 2.15 2.15c-.008 1.166-.925 2.116-2.075 2.15ZM7.575 14.817c-1.175.783-1.175 2.066 0 2.85 1.333.891 3.517.891 4.85 0 1.175-.784 1.175-2.067 0-2.85-1.325-.884-3.517-.884-4.85 0Z"
    />
  </svg>
);

export default People;
