import { useQuery } from 'react-query';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';

import * as Api from '../api';
import * as Actions from '../actions';
import * as Mappers from '../mappers';

const useLogin = ({ values, options = {} }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return useQuery(
    ['auth', 'login', values, options],
      async () => {
        const { data } = await Api.Login({ values });

        return Mappers.Token(data && data);
      },
      {
        ...options,
        enabled: !!values.code,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: ({ token }) => {
          navigate('/');
          dispatch(Actions.Login.success(token));
        }
      }
  );
};

export default useLogin;
