import React, { useState } from 'react';
import cx from 'classnames';

import cls from './Select.module.scss';

const Select = ({
  options,
  size = 'md',
  state = 'default',
  message,
  disabled,
  onChange,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <>
      <select
        className={cx(
          cls.select,
          cls[`select--size-${size}`],
          state && cls[`select--state-${state}`],
          isFocused && cls[`select--focused`],
          disabled && cls[`select--disabled`]
        )}
        {...props}
        {...{ disabled }}
        onChange={e => onChange && onChange(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {options.map(option => (
          <option value={option.value} key={option.value}>{option.title}</option>
        ))}
      </select>
      {!!message && <div className={cls.message}>{message}</div>}
    </>
  );
};

export default Select;
