import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';
import { TYPE } from "modules/useful/constants";

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('uz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['name']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Fields.Input
            required
            title='name'
            name={`name[${activeLanguage}]`}
          />
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Input
                required
                title='url'
                name='url'
              />
            </Col>
            <Col sm={12}>
              <Fields.Input
                required
                title='sort'
                name='sort'
                type='number'
              />
            </Col>
            <Col sm={12}>
              <Fields.Select
                required
                title='type'
                name='type'
                options={[
                  { value: '', title: 'select_type' },
                  { value: TYPE.RESOURCE, title: 'type_enum_RESOURCE' },
                  { value: TYPE.ABOUT_PORTAL, title: 'type_enum_ABOUT_PORTAL' }
                ]}
              />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;