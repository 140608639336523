import React from 'react';

import * as Icons from 'assets/icons';

import cls from './Filter.module.scss';

const Select = ({ options, value, setValue }) => (
  <ul className={cls.list}>
    {options.map((option, index) => (
      <li
        onClick={() => setValue(option.value)}
        className={cls.item}
        key={index}
      >
        <span className={cls.title}>{option.title}</span>
        {option.value == value && (
          <div className={cls.itemIcon}>
            <Icons.Check />
          </div>
        )}
      </li>
    ))}
  </ul>
);

export default Select;