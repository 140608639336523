import React from 'react';
import { useTranslation } from "react-i18next";

import { STATUS, COLORS } from 'modules/statistic/constants';

import * as Filter from 'containers/Filter';
import Dropdown from "containers/Dropdown";

const FilterList = ({ params, setParams }) => {
  const { t } = useTranslation();

  const STATUS_LIST = {
    ALL: t('all'),
    [STATUS.ACTIVE]: t('status_enum_ACTIVE'),
    [STATUS.INACTIVE]: t('status_enum_INACTIVE')
  };

  const COLOR_LIST = {
    ALL: t('all'),
    [COLORS.RED]: t('color_enum_RED'),
    [COLORS.PINK]: t('color_enum_PINK'),
    [COLORS.BLUE]: t('color_enum_BLUE'),
    [COLORS.GREEN]: t('color_enum_GREEN'),
    [COLORS.PURPLE]: t('color_enum_PURPLE'),
    [COLORS.WARNING]: t('color_enum_WARNING'),
    [COLORS.TURQUOISE]: t('color_enum_TURQUOISE'),
    [COLORS.LIGHTPURPLE]: t('color_enum_LIGHTPURPLE'),
  };

  const setParamValue = (name, value) => {
    setParams({ ...params, [name]: value || undefined, page: undefined });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
      <Dropdown
        name='title'
        value={params.title || ''}
        onCancel={() => setParamValue('title', '')}
      >
        <Filter.Input
          value={params.title || ''}
          setValue={value => setParamValue('title', value)}
        />
      </Dropdown>

      <Dropdown
        name='rating'
        value={params.rating || ''}
        onCancel={() => setParamValue('rating', '')}
      >
        <Filter.Input
          value={params.rating || ''}
          setValue={value => setParamValue('rating', value)}
        />
      </Dropdown>

      <Dropdown
        name='color'
        value={params.color || ''}
        onCancel={() => setParamValue('color', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: COLOR_LIST.ALL },
            { value: COLORS.RED, title: COLOR_LIST[COLORS.RED] },
            { value: COLORS.PINK, title: COLOR_LIST[COLORS.PINK] },
            { value: COLORS.BLUE, title: COLOR_LIST[COLORS.BLUE] },
            { value: COLORS.GREEN, title: COLOR_LIST[COLORS.GREEN] },
            { value: COLORS.PURPLE, title: COLOR_LIST[COLORS.PURPLE] },
            { value: COLORS.WARNING, title: COLOR_LIST[COLORS.WARNING] },
            { value: COLORS.TURQUOISE, title: COLOR_LIST[COLORS.TURQUOISE] },
            { value: COLORS.LIGHTPURPLE, title: COLOR_LIST[COLORS.LIGHTPURPLE] }
          ]}
          value={params.color || ''}
          setValue={value => setParamValue('color', value)}
        />
      </Dropdown>

      <Dropdown
        name='status'
        value={params.status || ''}
        onCancel={() => setParamValue('status', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.ACTIVE, title: STATUS_LIST[STATUS.ACTIVE] },
            { value: STATUS.INACTIVE, title: STATUS_LIST[STATUS.INACTIVE] }
          ]}
          value={params.status || ''}
          setValue={value => setParamValue('status', value)}
        />
      </Dropdown>
    </div>
  )
}

export default FilterList;