import { useState } from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';

let cancelSource = axios.CancelToken.source();

const useUpload = () => {
  const [progress, setProgress] = useState({
    percent: 0,
    total: '0 KB',
    loaded: '0 KB'
  });

  const cancel = () => cancelSource.cancel('canceled');

  const mutation = useMutation(
    async file => {
      cancelSource = axios.CancelToken.source();

      const { data } = await Api.Upload({
        file,
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);

          setProgress({ percent, total: Mappers.SizeFormat(event.total), loaded: Mappers.SizeFormat(event.loaded) });
        },
        cancelToken: cancelSource.token
      });

      return Mappers.File(data && data.data);
    },
    {
      onMutate: () => {
        setProgress({
          percent: 0,
          total: '0 KB',
          loaded: '0 KB'
        });
      }
    }
  );

  return {
    ...mutation,
    progress,
    cancel
  };
};

export default useUpload;
