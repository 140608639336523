import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/expert/list-expert', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/expert/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/expert', {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    description: {
      uz: values.description.uz,
      oz: values.description.oz,
      ru: values.description.ru,
      en: values.description.en
    },
    fileId: values.photoId,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/expert/${id}`, {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    description: {
      uz: values.description.uz,
      oz: values.description.oz,
      ru: values.description.ru,
      en: values.description.en
    },
    fileId: values.photoId,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/expert/${id}`);