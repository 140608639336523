import React from 'react';
import { useUrlSearchParams } from "use-url-search-params";

import config from 'config';

import * as Hooks from 'modules/auth/hooks';

import classes from './Auth.module.scss';

const Auth = () => {
  const [params, setParams] = useUrlSearchParams();

  Hooks.useLogin({
    values: {
      code: params.code
    },
    options: {
      onError: () => {
        setParams({ code: undefined });
      }
    }
  });

  return (
    <div className={classes.wrapper}>
      <img src={require('assets/images/logo.png')} alt="Logo" width={75} />
      <p>B advice admin panel</p>
      <a href={config.sso.link}>Kirish</a>
    </div>
  )
}

export default Auth;