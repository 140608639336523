import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import { TYPE } from 'modules/statisticCategory/constants';
import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('uz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['statisticName']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Fields.Input
            required
            title='name'
            name={`statisticName[${activeLanguage}]`}
          />
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                required
                title='chart_type'
                name="typeGraphic"
                options={[
                  { value: '', title: 'select_chart_type' },
                  { value: TYPE.PIE_CHART, title: 'chart_type_enum_PIE_CHART' },
                  { value: TYPE.BAR_CHART, title: 'chart_type_enum_BAR_CHART' },
                  { value: TYPE.SQUARE_CHART, title: 'chart_type_enum_SQUARE_CHART' }
                ]}
              />
            </Col>

            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;