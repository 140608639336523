import React from 'react';

const Uzbekistan = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <g clipPath="url(#a)">
      <path
        fill="#F0F0F0"
        d="M.567 8.348A11.988 11.988 0 0 0 0 12c0 1.274.2 2.5.567 3.652L12 16.174l11.433-.522A11.99 11.99 0 0 0 24 12c0-1.274-.2-2.5-.567-3.652L12 7.826.567 8.348Z"
      />
      <path
        fill="#D80027"
        d="m23.433 8.348-11.434-.522-11.433.522c-.11.341-.203.69-.282 1.043h23.43a11.935 11.935 0 0 0-.281-1.043ZM.284 14.609c.078.354.172.702.28 1.043h.002l11.432.522 11.434-.522c.109-.341.202-.69.28-1.043H.285Z"
      />
      <path fill="#6DA544" d="M12 24c5.353 0 9.887-3.507 11.433-8.348H.566C2.11 20.493 6.646 24 11.999 24Z" />
      <path fill="#338AF3" d="M12 0C6.646 0 2.112 3.507.567 8.348h22.866C21.888 3.506 17.353 0 12 0Z" />
      <path
        fill="#F0F0F0"
        d="M5.484 4.957c0-1.124.79-2.063 1.845-2.294a2.348 2.348 0 1 0 0 4.586 2.348 2.348 0 0 1-1.845-2.292ZM8.716 6.023l.159.49h.514l-.416.302.16.49-.417-.303-.416.302.159-.49-.416-.301h.514l.159-.49ZM10.358 6.023l.16.49h.513l-.416.302.16.49-.417-.303-.416.302.16-.49-.417-.301h.514l.16-.49ZM12 6.023l.158.49h.514l-.416.302.16.49-.417-.303-.416.302.16-.49-.417-.301h.514l.16-.49ZM13.642 6.023l.16.49h.514l-.417.302.16.49-.417-.303-.416.302.16-.49-.417-.301h.514l.16-.49ZM15.283 6.023l.159.49h.514l-.416.302.159.49-.416-.303-.416.302.158-.49-.416-.301h.515l.159-.49ZM10.358 4.316l.16.49h.513l-.416.302.16.489-.417-.303-.416.303.16-.49-.417-.302h.514l.16-.489ZM12 4.316l.158.49h.514l-.416.302.16.489-.417-.303-.416.303.16-.49-.417-.302h.514l.16-.489ZM13.642 4.316l.16.49h.514l-.417.302.16.489-.417-.303-.416.303.16-.49-.417-.302h.514l.16-.489ZM15.283 4.316l.159.49h.514l-.416.302.159.489-.416-.303-.416.303.158-.49-.416-.302h.515l.159-.489ZM12 2.609l.158.489h.514l-.416.302.16.49-.417-.303-.416.302.16-.489-.417-.302h.514l.16-.49ZM13.642 2.609l.16.489h.514l-.417.302.16.49-.417-.303-.416.302.16-.489-.417-.302h.514l.16-.49ZM15.283 2.609l.159.489h.514l-.416.302.159.49-.416-.303-.416.302.158-.489-.416-.302h.515l.159-.49Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Uzbekistan;
