import React from 'react';
import cx from 'classnames';

import Default from './Default';
import Preview from './Preview';
import Progress from './Progress';

import classes from './Uploader.module.scss';

const Uploader = ({
  state = 'default',
  accept,
  type,
  details,
  onSelect,
  file,
  progress,
  message
}) => {
  let content;

  if (state === 'progress' && progress) {
    content = <Progress {...{ type }} {...progress} />;
  } else if (state === 'preview' && file) {
    content = <Preview {...{ type, details }} {...file} />;
  } else {
    content = <Default {...{ accept, type, details, onSelect }} />;
  }

  return (
    <div className={cx(classes.wrapper, state && classes[`wrapper--state-${state}`])}>
      <div className={classes.wrapperInner}>{content}</div>
      {!!message && <div className={classes.message}>{message}</div>}
    </div>
  );
};

export default Uploader;
