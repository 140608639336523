import React from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

import classes from './Modal.module.scss';

const Modal = ({ visible, maxWidth = 500, onCancel, children }) => (
   <div
      onClick={onCancel && onCancel}
      className={cx(classes.wrapper, visible && classes['wrapper--open'])}
   >
      <div
         onClick={e => e.stopPropagation()}
         className={classes.content}
         style={{ '--max-width': `${maxWidth}px` }}
      >
         {/*<Icon onClick={onCancel} className={classes.closeIcon} name='TextGrammarSettings' />*/}

         {children}
      </div>
   </div>
);

export default Modal;