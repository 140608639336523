export const LOGIN_REQUEST = '@@AUTH/LOGIN/REQUEST';
export const LOGIN_SUCCESS = '@@AUTH/LOGIN/SUCCESS';
export const LOGIN_ERROR = '@@AUTH/LOGIN/ERROR';

export const PROFILE_REQUEST = '@@AUTH/PROFILE/REQUEST';
export const PROFILE_SUCCESS = '@@AUTH/PROFILE/SUCCESS';
export const PROFILE_ERROR = '@@AUTH/PROFILE/ERROR';

export const LOGOUT_REQUEST = '@@AUTH/LOGOUT/REQUEST';
export const LOGOUT_SUCCESS = '@@AUTH/LOGOUT/SUCCESS';

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  CLIENT: 'ROLE_CLIENT',
  CALL_MANAGER: 'CALL_MANAGER',
  CONTENT_MANAGER: 'ROLE_CONTENT_MANAGER'
}