import React from 'react';

const CourtHouse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M1.667 18.333h16.666M10 1.667a5.625 5.625 0 0 0 4.167 0v2.5a5.625 5.625 0 0 1-4.167 0v-2.5ZM10 4.167v2.5M14.167 6.667H5.833c-1.666 0-2.5.833-2.5 2.5v9.166h13.334V9.167c0-1.667-.834-2.5-2.5-2.5ZM3.817 10h12.366"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M6.658 10v8.333M9.992 10v8.333M13.325 10v8.333"
      opacity=".4"
    />
  </svg>
);

export default CourtHouse;
