import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('uz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['comment']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Fields.Editor
            required
            title='comment'
            name={`comment[${activeLanguage}]`}
          />
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Input
                required
                title='full_name'
                name='fullName'
              />
            </Col>
            <Col sm={12}>
              <Fields.Uploader
                required
                title='photo'
                name="photoId"
                type="image"
                accept={['image/*']}
                maxFileSize={10240}
                details={{ resolution: '512 x 512', extension: 'svg,', size: '10 мб' }}
              />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;