import React, { useState } from 'react';
import { useField } from 'formik';
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/file/hooks';
import * as Helpers from 'helpers';

import Label from "components/Label";
import Modal from 'components/Modal';
import UploaderBase from 'components/Uploader';

const Uploader = ({ title, name, type, required, accept, maxFileSize, details }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ name });

  const { progress, ...mutation } = Hooks.useUpload();

  const { item } = Hooks.useSingle({ id: field.value });

  const [isView, setView] = useState(false);

  const onSelect = (file) => {
    if (maxFileSize && file.size > maxFileSize * 1000) {
      helpers.setError('Размер файла слишком велик');
      return;
    }

    helpers.setError(undefined);

    mutation.mutate(file , {
        onSuccess: data => {
          helpers.setValue(data.id);
        }
      }
    );
  };

  const onDownload = (fileId) => {
    const link = document.createElement('a');
    const url = Helpers.File.Url(fileId);

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', 'file.pdf');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  let state;

  const status = {
    loading: 'progress',
    error: 'error',
    success: 'success'
  };

  if (field.value && item.id) {
    state = 'preview';
  } else if (meta.error) {
    state = 'error';
  } else if (field.value && !item.id) {
    state = 'progress';
  } else {
    state = status[mutation.status];
  }

  return (
    <>
      <Label title={t(title)} {...{ required }} />
      <UploaderBase
        {...{ state, type, accept, details, onSelect }}
        progress={{
          percent: progress.percent,
          total: progress.total,
          loaded: progress.loaded,
          onCancel: () => mutation.cancel()
        }}
        file={{
          name: item.name,
          photoId: field.value,
          size: item.size,
          extension: item.extension,
          onView: () => {
            if (type === 'image') {
              setView(true)
            } else {
              onDownload(field.value);
            }
          },
          onRemove: () => helpers.setValue('')
        }}
        message={meta.error}
      />
      <Modal visible={isView} onCancel={() => setView(false)} maxWidth={800}>
        {!!field.value && (
          <img src={Helpers.Image.Url(field.value)} style={{ width: 'auto', display: 'block', margin: 'auto', maxWidth: 600 }} />
        )}
      </Modal>
    </>
  );
};

export default Uploader;
