import React from 'react';

import classes from './Uploader.module.scss';

const Details = ({ resolution, extension, size }) => (
  <div className={classes.detailList}>
    {resolution && <div className={classes.detailItem}>Размер: {resolution}</div>}
    {extension && <div className={classes.detailItem}>Формат: {extension}</div>}
    {size && <div className={classes.detailItem}>Вес: {size}</div>}
  </div>
);

export default Details;
