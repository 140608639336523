import React from 'react';
import DataTable from "react-data-table-component";

import './Table.scss';

const Table = ({ columns, data, ...props }) => (
  <DataTable
    columns={columns}
    data={data}
    { ...props }
  />
);

export default Table;