import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
   .use(initReactI18next)
   .use(LanguageDetector)
   .use(HttpApi)
   .init({
      supportedLngs: ['uz', 'ru', 'en'],
      lng: 'uz',
      fallbackLng: "uz",
      detection: {
         order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
         caches: ['localStorage', 'cookie']
      },
      backend: {
         loadPath: '/locales/{{lng}}/translation.json',
      }
   });

export default i18n;