import get from 'lodash/get';
import moment from 'moment';

export const Media = (item) => {
  const createdAt = moment(get(item, 'createdAt'), 'DD.MM.YYYY HH:mm:SS');

  return {
    id: get(item, 'id') || '',
    shortTitle: {
      uz: get(item, 'shortTitle.uz') || '',
      oz: get(item, 'shortTitle.oz') || '',
      ru: get(item, 'shortTitle.ru') || '',
      en: get(item, 'shortTitle.en') || '',
    },
    about: {
      uz: get(item, 'about.uz') || '',
      oz: get(item, 'about.oz') || '',
      ru: get(item, 'about.ru') || '',
      en: get(item, 'about.en') || '',
    },
    mediaLibType: get(item, 'mediaLibType') || '',
    photoId: get(item, 'photoId') || '',
    fileId: get(item, 'fileId') || '',
    createdAt: get(item, 'createdAt') && createdAt.isValid() ? createdAt.format('DD.MM.YYYY') : '',
    status: get(item, 'status') || ''
  }
};

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalCount') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 10
});