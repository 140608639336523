import React, { useLayoutEffect } from 'react';
import { useField } from 'formik';

import SwitchBase from 'components/Switch';

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

const Switch = ({ name, ...props }) => {
  const [field, , helper] = useField({ name });

  useLayoutEffect(() => {
    helper.setValue(STATUS.ACTIVE);
  }, []);

  return (
    <SwitchBase
      {...props}
      checked={field.value === STATUS.ACTIVE}
      onChange={value => {
        const status = value ? STATUS.ACTIVE : STATUS.INACTIVE;

        helper.setValue(status);
      }}
    />
  );
};

export default Switch;