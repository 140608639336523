import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";

import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Create = ({ onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Create({ values });

      return Mappers.Advice(data && data.data);
    },
      {
        onSuccess,
        onError,
        onSettled
      }
  );

  const validationSchema = yup.object().shape({
    name: yup.object().shape({
      uz: yup.string().required(t('required')),
      oz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    })
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onError: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        name: {
          uz: '',
          oz: '',
          ru: '',
          en: ''
        },
        parentId: '',
        photoId: '',
        status: ''
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
      >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Create;
