import React from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import LanguageTabBase from "components/LanguageTab";

const langs = [
  { title: 'O’zbekcha', value: 'uz', icon: require('assets/images/uz.svg').default },
  { title: 'Ўзбекча', value: 'oz', icon: require('assets/images/uz.svg').default },
  { title: 'Русский', value: 'ru', icon: require('assets/images/ru.svg').default },
  { title: 'English', value: 'en', icon: require('assets/images/en.svg').default }
];

const LanguageTab = ({ fields, active, onChange, languages = langs }) => {
  const { errors, touched } = useFormikContext();

  const errorsCountByLanguage = languages.reduce((prev, language) => {
    const count = fields.reduce(
      (prev, field) => prev + Number(!!get(errors, `${field}[${language.value}]`) && !!get(touched, `${field}[${language.value}]`)),
      0
    );

    return {
      ...prev,
      [language.value]: count
    };
  }, {});

  return (
    <LanguageTabBase
      active={active}
      onChange={onChange}
      languages={languages
        .map(language => ({
          ...language,
          badge: errorsCountByLanguage[language.value]
        }))}
    />
  )
}

export default LanguageTab;