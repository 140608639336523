import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/export-main/list-export-main', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/export-main/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/export-main', {
    title: {
      uz: values.title.uz,
      oz: values.title.oz,
      ru: values.title.ru,
      en: values.title.en
    },
    text: {
      uz: values.text.uz,
      oz: values.text.oz,
      ru: values.text.ru,
      en: values.text.en
    },
    photoId: values.photoId,
    blogPayload: {
      info: {
        uz: values.info.uz,
        oz: values.info.oz,
        ru: values.info.ru,
        en: values.info.en
      },
      reqDocs: {
        uz: values.reqDocs.uz,
        oz: values.reqDocs.oz,
        ru: values.reqDocs.ru,
        en: values.reqDocs.en
      }
    },
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/export-main/${id}`, {
    title: {
      uz: values.title.uz,
      oz: values.title.oz,
      ru: values.title.ru,
      en: values.title.en
    },
    text: {
      uz: values.text.uz,
      oz: values.text.oz,
      ru: values.text.ru,
      en: values.text.en
    },
    photoId: values.photoId,
    blogPayload: {
      info: {
        uz: values.info.uz,
        oz: values.info.oz,
        ru: values.info.ru,
        en: values.info.en
      },
      reqDocs: {
        uz: values.reqDocs.uz,
        oz: values.reqDocs.oz,
        ru: values.reqDocs.ru,
        en: values.reqDocs.en
      }
    },
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/export-main/${id}`);