import { http } from "services";

export const Login = ({ values }) => http.request.post('/api/v1/sso/access-token', { }, {
  params: {
    code: values.code,
    client_type: 'ADMIN_CABINET'
  }
});

export const Profile = ({ token }) => http.request.get('/api/v1/admin/user/me', {
  headers: {
    'Authorization': `Bearer ${token}`
  }
});