import React from 'react';

import * as Filter from 'containers/Filter';
import Dropdown from "containers/Dropdown";

const FilterList = ({ params, setParams }) => {
  const setParamValue = (name, value) => {
    setParams({ ...params, [name]: value || undefined, page: undefined });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
      <Dropdown
        name='name'
        value={params.name || ''}
        onCancel={() => setParamValue('name', '')}
      >
        <Filter.Input
          value={params.name || ''}
          setValue={value => setParamValue('name', value)}
        />
      </Dropdown>

      <Dropdown
        name='phone'
        value={params.phone || ''}
        onCancel={() => setParamValue('phone', '')}
      >
        <Filter.Input
          value={params.phone || ''}
          setValue={value => setParamValue('phone', value)}
        />
      </Dropdown>
    </div>
  )
}

export default FilterList;