import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-grid-system';

import * as Hooks from 'modules/advice/hooks';
import { CATEGORY_INVESTOR } from "modules/blogForAdvice/constants";

import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = ({ values }) => {
  const { i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState('uz');

  const parentAdvices = Hooks.useParentList();

  const childAdvices = Hooks.useChildList({
    parentId: values.adviceParentId
  });

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['titleCarousel', 'titleInvestor', 'info', 'reqDocs']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            {values.showCarousel && (
              <Col sm={12}>
                <Fields.Input
                  title='carousel_title'
                  name={`titleCarousel[${activeLanguage}]`}
                />
              </Col>
            )}
            {values.showInvestor && (
              <Col sm={12}>
                <Fields.Input
                  title='investor_title'
                  name={`titleInvestor[${activeLanguage}]`}
                />
              </Col>
            )}
            <Col sm={12}>
              <Fields.Input
                required
                title='info'
                name={`info[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Editor
                required
                title='req_docs'
                name={`reqDocs[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                required
                title='advice_parent'
                name='adviceParentId'
                options={[
                  { value: '', title: 'select_advice' },
                  ...parentAdvices.items.map(item => ({ value: item.id, title: item.name[i18n.language]}))
                ]}
              />
            </Col>
            {values.adviceParentId && (
              <Col sm={12}>
                <Fields.Select
                  required
                  title='advice_child'
                  name='adviceId'
                  options={[
                    { value: '', title: 'select_advice' },
                    ...childAdvices.items.map(item => ({ value: item.id, title: item.name[i18n.language]}))
                  ]}
                />
              </Col>
            )}
            <Col sm={12}>
              <Fields.Checkbox
                title='show_carousel'
                name='showCarousel'
              />
            </Col>
            {values.showCarousel && (
              <Col sm={12}>
                <Fields.Uploader
                  title='carousel_icon'
                  name="iconId"
                  type="image"
                  accept={['image/*']}
                  maxFileSize={10240}
                  details={{ resolution: '512 x 512', extension: 'svg,', size: '10 мб' }}
                />
              </Col>
            )}
            <Col sm={12}>
              <Fields.Checkbox
                title='show_investor'
                name='showInvestor'
              />
            </Col>
            {values.showInvestor && (
              <>
                <Col sm={12}>
                  <Fields.Select
                    title='category_investor'
                    name='categoryInvestor'
                    options={[
                      { value: '', title: 'select_category_investor' },
                      { value: CATEGORY_INVESTOR.MAIN, title: 'category_investor_MAIN' },
                      { value: CATEGORY_INVESTOR.LEGISLATION, title: 'category_investor_LEGISLATION' },
                      { value: CATEGORY_INVESTOR.CONDITION_TERRITORIES, title: 'category_investor_CONDITION_TERRITORIES' },
                    ]}
                  />
                </Col>
                <Col sm={12}>
                  <Fields.Uploader
                    title='investor_photo'
                    name="photoId"
                    type="image"
                    accept={['image/*']}
                    maxFileSize={10240}
                    details={{ resolution: '512 x 512', extension: 'svg,', size: '10 мб' }}
                  />
                </Col>              </>
            )}
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;