import React from 'react';

const English = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill="#F0F0F0"
    />
    <path
      d="M1.85954 3.52057C1.15258 4.44036 0.619507 5.5005 0.309113 6.65218H4.99115L1.85954 3.52057Z"
      fill="#0052B4"
    />
    <path d="M17.688 6.65221C17.3776 5.50056 16.8444 4.44042 16.1375 3.52063L13.006 6.65221H17.688Z" fill="#0052B4" />
    <path
      d="M0.309113 11.3478C0.619542 12.4994 1.15262 13.5596 1.85954 14.4793L4.99105 11.3478H0.309113Z"
      fill="#0052B4"
    />
    <path d="M14.4772 1.86046C13.5574 1.1535 12.4973 0.620427 11.3456 0.309998V4.992L14.4772 1.86046Z" fill="#0052B4" />
    <path d="M3.5188 16.1396C4.43859 16.8466 5.49873 17.3797 6.65038 17.6901V13.0081L3.5188 16.1396Z" fill="#0052B4" />
    <path
      d="M6.65034 0.309998C5.49869 0.620427 4.43856 1.1535 3.5188 1.86042L6.65034 4.99197V0.309998Z"
      fill="#0052B4"
    />
    <path d="M11.3463 17.6901C12.498 17.3797 13.5581 16.8466 14.4779 16.1397L11.3463 13.0081V17.6901Z" fill="#0052B4" />
    <path d="M13.006 11.3478L16.1375 14.4794C16.8444 13.5596 17.3776 12.4994 17.688 11.3478H13.006Z" fill="#0052B4" />
    <path
      d="M17.9238 7.8261H10.174H10.1739V0.0761836C9.78964 0.0261563 9.39786 0 9 0C8.60207 0 8.21035 0.0261563 7.8261 0.0761836V7.82603V7.82606H0.0761836C0.0261563 8.21036 0 8.60214 0 9C0 9.39793 0.0261563 9.78964 0.0761836 10.1739H7.82603H7.82606V17.9238C8.21036 17.9738 8.60207 18 9 18C9.39786 18 9.78964 17.9739 10.1739 17.9238V10.174V10.1739H17.9238C17.9738 9.78964 18 9.39793 18 9C18 8.60214 17.9738 8.21035 17.9238 7.8261Z"
      fill="#D80027"
    />
    <path
      d="M11.3491 11.3477L15.3652 15.3638C15.5499 15.1792 15.7261 14.9861 15.8942 14.786L12.4559 11.3477H11.3491V11.3477Z"
      fill="#D80027"
    />
    <path
      d="M6.65182 11.3477H6.65175L2.63568 15.3638C2.82032 15.5485 3.01336 15.7247 3.21347 15.8928L6.65182 12.4544V11.3477Z"
      fill="#D80027"
    />
    <path
      d="M6.65174 6.65227V6.6522L2.63563 2.63605C2.45092 2.82069 2.27472 3.01373 2.1066 3.21384L5.54498 6.65223H6.65174V6.65227Z"
      fill="#D80027"
    />
    <path
      d="M11.3491 6.65217L15.3652 2.63599C15.1806 2.45128 14.9876 2.27508 14.7874 2.10699L11.3491 5.54538V6.65217Z"
      fill="#D80027"
    />
  </svg>
);

export default English;
