import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';
import { TYPE } from 'modules/media/constants';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = ({ values }) => {
  const [activeLanguage, setActiveLanguage] = useState('uz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['shortTitle', 'about']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Input
                required
                title='title'
                name={`shortTitle[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Textarea
                required
                title='about'
                name={`about[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                required
                title='type'
                name='mediaLibType'
                options={[
                  { value: '', title: 'select_type' },
                  { value: TYPE.MEDIATEKA, title: 'type_enum_MEDIATEKA' },
                  { value: TYPE.GET_INFO, title: 'type_enum_GET_INFO' }
                ]}
              />
            </Col>
            {values.mediaLibType === TYPE.MEDIATEKA && (
              <Col sm={12}>
                <Fields.Uploader
                  title='photo'
                  name="photoId"
                  type="image"
                  accept={['image/*']}
                  maxFileSize={10240}
                  details={{ resolution: '512 x 512', extension: 'svg,', size: '10 мб' }}
                />
              </Col>
            )}
            <Col sm={12}>
              <Fields.Uploader
                required
                title='file'
                name="fileId"
                type="file"
                accept={['.pdf']}
                maxFileSize={10240}
                details={{ resolution: '512 x 512', extension: 'svg,', size: '10 мб' }}
              />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;