import React from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

import cls from './Checkbox.module.scss';

const Checkbox = ({ checked, disabled, onChange }) => (
  <div
    className={cx(cls.wrapper, disabled && cls['wrapper--disabled'], checked && cls[`wrapper--checked`])}
    onClick={() => onChange && onChange(!checked)}
  >
    <div className={cls.icon}>
      <Icon name="Checkmark" size={16} />
    </div>
  </div>
);

export default Checkbox;
