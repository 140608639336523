import React from 'react';
import { useTranslation } from "react-i18next";

import { STATUS } from 'modules/hotline/constants';

import * as Filter from 'containers/Filter';
import Dropdown from "containers/Dropdown";

const FilterList = ({ params, setParams }) => {
  const { t } = useTranslation();

  const STATUS_LIST = {
    ALL: t('all'),
    [STATUS.ACTIVE]: t('status_enum_ACTIVE'),
    [STATUS.INACTIVE]: t('status_enum_INACTIVE')
  };

  const setParamValue = (name, value) => {
    setParams({ ...params, [name]: value || undefined, page: undefined });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
      <Dropdown
        name='url'
        value={params.url || ''}
        onCancel={() => setParamValue('url', '')}
      >
        <Filter.Input
          value={params.url || ''}
          setValue={value => setParamValue('url', value)}
        />
      </Dropdown>

      <Dropdown
        name='status'
        value={params.status || ''}
        onCancel={() => setParamValue('status', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.ACTIVE, title: STATUS_LIST[STATUS.ACTIVE] },
            { value: STATUS.INACTIVE, title: STATUS_LIST[STATUS.INACTIVE] }
          ]}
          value={params.status || ''}
          setValue={value => setParamValue('status', value)}
        />
      </Dropdown>
    </div>
  )
}

export default FilterList;