import { http } from 'services';

export const List = ({ params }) =>
  http.request.post('/api/v1/beck/file/pageable', {
    page: params.page,
    per_page: params.perPage,
    sort: params.sort,
    search: params.filter
  });

export const Single = ({ id }) => http.request.get(`/api/v1/file/open-source/info/${id}`);

export const Upload = ({ file, onUploadProgress, cancelToken }) => {
  const data = new FormData();

  data.append('file', file);

  return http.request.post('/api/v1/file/open-source/upload', data, {
    onUploadProgress,
    cancelToken
  });
};

export const Delete = ({ id }) => http.request.delete(`/api/v1/beck/file/${id}`);
