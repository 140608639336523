import get from 'lodash/get';
import moment from 'moment';

export const User = (item) => {
  const createdAt = moment(get(item, 'createdAt'), 'DD.MM.YYYY HH:mm:SS');

  return {
    id: get(item, 'id') || '',
    name: get(item, 'fullName') || '',
    email: get(item, 'email') || '',
    phone: get(item, 'phoneNumber') || '',
    roleName: get(item, 'roleName') || '',
    type: get(item, 'type') || '',
    createdAt: get(item, 'createdAt') && createdAt.isValid() ? createdAt.format('DD.MM.YYYY') : ''
  }
};

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalCount') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 10
});