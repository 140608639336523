import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-grid-system';

import * as Hooks from 'modules/hotlineCategory/hooks';
import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const { i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState('uz');

  const { items } = Hooks.useListAll();

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['name']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Fields.Input
            required
            title='name'
            name={`name[${activeLanguage}]`}
          />
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                required
                title='select_category'
                name="categoryId"
                options={[
                  { value: '', title: 'select_category' },
                  ...items.map(item => ({ value: item.id, title: item.name[i18n.language] }))
                ]}
              />
            </Col>
            <Col sm={12}>
              <Fields.Input
                required
                title='number'
                name='number'
              />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;