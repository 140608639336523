import { http } from 'services';

export const Single = ({ type }) => http.request.get(`/api/v1/admin/info-pages/${type}`);

export const infoPages = ({ values }) => http.request.post('/api/v1/admin/info-pages', {
    info: {
      uz: values.info.uz,
      oz: values.info.oz,
      ru: values.info.ru,
      en: values.info.en
    },
    reqDocs: {
      uz: values.reqDocs.uz,
      oz: values.reqDocs.oz,
      ru: values.reqDocs.ru,
      en: values.reqDocs.en
    },
    pageName: values.pageName
  }
);