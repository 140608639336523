import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/hotlines/c/list/hotlines/c/admin', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const ListAll = () => http.request.get('/api/v1/admin/hotlines/c/list/hotlines/c');

export const Single = ({ id }) => http.request.get(`/api/v1/admin/hotlines/c/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/hotlines/c', {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/hotlines/c/${id}`, {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/hotlines/c/${id}`);