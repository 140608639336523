import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Hooks from "modules/article/hooks";
import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = ({ values }) => {
  const [activeLanguage, setActiveLanguage] = useState('uz');

  const { items } = Hooks.useParentList();

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['name']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Fields.Input
            required
            title='name'
            name={`name[${activeLanguage}]`}
          />
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                title='parent'
                name='parentId'
                options={[
                  { value: '', title: 'select_parent_advice' },
                  ...items.map(item => ({ value: item.id, title: item.name['uz'] }))
                ]}
              />
            </Col>
            {!values.parentId && (
              <Col sm={12}>
                <Fields.Uploader
                  title='photo'
                  name="photoId"
                  type="image"
                  accept={['image/*']}
                  maxFileSize={10240}
                  details={{ resolution: '512 x 512', extension: 'svg,', size: '10 мб' }}
                />
              </Col>
            )}
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;