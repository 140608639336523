import React from 'react';

import * as Hooks from "modules/auth/hooks";

import RoleDenied from 'pages/RoleDenied';

const RequireRole = ({ roles, page }) => {
  const { profile } = Hooks.useAuth();

  if (roles.includes(profile.role)) return <>{page}</>;

  return <RoleDenied />;
};

export default RequireRole;
