import React from 'react';

import classes from './TopBar.module.scss';

const TopBar = ({ left, right }) => (
  <div className={classes.wrapper}>
    {!!left && <div className={classes.left}>{left}</div>}
    {!!right && <div className={classes.right}>{right}</div>}
  </div>
);

export default TopBar;