import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";

import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Update = ({ id, values, onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Update({ id, values });

      return Mappers.BlogForAdvice(data && data.data);
    },
      {
        onSuccess,
        onError,
        onSettled
      }
  );

  const validationSchema = yup.object().shape({
    // titleCarousel: yup.object().shape({
    //   uz: yup.string().required(t('required')),
    //   oz: yup.string().required(t('required')),
    //   ru: yup.string().required(t('required')),
    //   en: yup.string().required(t('required'))
    // }),
    // titleInvestor: yup.object().shape({
    //   uz: yup.string().required(t('required')),
    //   oz: yup.string().required(t('required')),
    //   ru: yup.string().required(t('required')),
    //   en: yup.string().required(t('required'))
    // }),
    info: yup.object().shape({
      uz: yup.string().required(t('required')),
      oz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    reqDocs: yup.object().shape({
      uz: yup.string().required(t('required')),
      oz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    adviceParentId: yup.string().required(t('required')),
    adviceId: yup.string().required(t('required')),
    // iconId: yup.string().required(t('required')),
    // photoId: yup.string().required(t('required'))
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        titleCarousel: {
          uz: values.titleCarousel.uz,
          oz: values.titleCarousel.oz,
          ru: values.titleCarousel.ru,
          en: values.titleCarousel.en
        },
        titleInvestor: {
          uz: values.titleInvestor.uz,
          oz: values.titleInvestor.oz,
          ru: values.titleInvestor.ru,
          en: values.titleInvestor.en
        },
        info: {
          uz: values.info.uz,
          oz: values.info.oz,
          ru: values.info.ru,
          en: values.info.en
        },
        reqDocs: {
          uz: values.reqDocs.uz,
          oz: values.reqDocs.oz,
          ru: values.reqDocs.ru,
          en: values.reqDocs.en
        },
        adviceParentId: values.adviceParentId,
        adviceId: values.adviceId,
        showCarousel: values.showCarousel,
        showInvestor: values.showInvestor,
        categoryInvestor: values.categoryInvestor,
        iconId: values.iconId,
        photoId: values.photoId,
        status: values.status
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Update;
