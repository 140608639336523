import React from "react";
import { Routes, Route, Navigate } from "react-router";

import * as Hooks from "modules/auth/hooks";
import { ROLES } from "modules/auth/constants";

import * as Layout from "layouts";

import Auth from 'pages/Auth';

import User from "pages/User";
import Call from "pages/Call";
import InfoPages from "pages/InfoPages";

import * as Faq from "pages/Faq";
import * as New from "pages/New";
import * as Media from "pages/Media";
import * as Video from "pages/Video";
import * as Banner from "pages/Banner";
import * as Advice from "pages/Advice";
import * as Export from "pages/Export";
import * as Expert from "pages/Expert";
import * as Useful from "pages/Useful";
import * as Comment from "pages/Comment";
import * as Hotline from "pages/Hotline";
import * as Network from "pages/Network";
import * as Article from "pages/Article";
import * as Statistic from "pages/Statistic";
import * as OurService from "pages/OurService";
import * as BestComment from "pages/BestComment";
import * as Translation from "pages/Translation";
import * as BlogForAdvice from "pages/BlogForAdvice";
import * as BlogForArticle from "pages/BlogForArticle";
import * as BusinessArticle from "pages/BusinessArticle";
import * as HotlineCategory from "pages/HotlineCategory";
import * as BlogForOurService from "pages/BlogForOurService";
import * as StatisticCategory from "pages/StatisticCategory";
import * as BusinessArticleType from "pages/BusinessArticleType";

import RequireRole from 'require/RequireRole';

import Spinner from "components/Spinner";

const App = () => {
  const { isAuthenticated, isFetched } = Hooks.useAuth();

  if (!isFetched) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return (
      <Layout.Auth>
        <Routes>
          <Route path="*" element={<Auth />} />
        </Routes>
      </Layout.Auth>
    )
  }

  return (
    <Layout.Main>
      <Routes>
        <Route
          path="/user"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<User />} />
          }
        />

        <Route
          path="/call"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CALL_MANAGER]} page={<Call />} />
          }
        />

        <Route
          path="/faq"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Faq.List />} />
          }
        />

        <Route
          path="/faq/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Faq.Create />} />
          }
        />

        <Route
          path="/faq/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Faq.Update />} />
          }
        />

        <Route
          path="/new"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<New.List />} />
          }
        />

        <Route
          path="/new/create"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<New.Create />} />
          }
        />

        <Route
          path="/new/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<New.Update />} />
          }
        />

        <Route
          path="/advice"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Advice.List />} />
          }
        />

        <Route
          path="/advice/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Advice.Create />} />
          }
        />

        <Route
          path="/advice/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Advice.Update />} />
          }
        />

        <Route
          path="/blog-for-advice"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<BlogForAdvice.List />} />
          }
        />

        <Route
          path="/blog-for-advice/create"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<BlogForAdvice.Create />} />
          }
        />

        <Route
          path="/blog-for-advice/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<BlogForAdvice.Update />} />
          }
        />

        <Route
          path="/article"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Article.List />} />
          }
        />

        <Route
          path="/article/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Article.Create />} />
          }
        />

        <Route
          path="/article/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Article.Update />} />
          }
        />

        <Route
          path="/blog-for-article"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<BlogForArticle.List />} />
          }
        />

        <Route
          path="/blog-for-article/create"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<BlogForArticle.Create />} />
          }
        />

        <Route
          path="/blog-for-article/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<BlogForArticle.Update />} />
          }
        />

        <Route
          path="/our-service"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<OurService.List />} />
          }
        />

        <Route
          path="/our-service/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<OurService.Create />} />
          }
        />

        <Route
          path="/our-service/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<OurService.Update />} />
          }
        />

        <Route
          path="/blog-for-our-service"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BlogForOurService.List />} />
          }
        />

        <Route
          path="/blog-for-our-service/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BlogForOurService.Create />} />
          }
        />

        <Route
          path="/blog-for-our-service/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BlogForOurService.Update />} />
          }
        />

        <Route
          path="/hotline"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Hotline.List />} />
          }
        />

        <Route
          path="/hotline/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Hotline.Create />} />
          }
        />

        <Route
          path="/hotline/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Hotline.Update />} />
          }
        />

        <Route
          path="/hotline-category"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<HotlineCategory.List />} />
          }
        />

        <Route
          path="/hotline-category/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<HotlineCategory.Create />} />
          }
        />

        <Route
          path="/hotline-category/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<HotlineCategory.Update />} />
          }
        />

        <Route
          path="/expert"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Expert.List />} />
          }
        />

        <Route
          path="/expert/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Expert.Create />} />
          }
        />

        <Route
          path="/expert/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Expert.Update />} />
          }
        />

        <Route
          path="/statistic"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Statistic.List />} />
          }
        />

        <Route
          path="/statistic/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Statistic.Create />} />
          }
        />

        <Route
          path="/statistic/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Statistic.Update />} />
          }
        />

        <Route
          path="/statistic-category"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<StatisticCategory.List />} />
          }
        />

        <Route
          path="/statistic-category/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<StatisticCategory.Create />} />
          }
        />

        <Route
          path="/statistic-category/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<StatisticCategory.Update />} />
          }
        />

        <Route
          path="/export"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<Export.List />} />
          }
        />

        <Route
          path="/export/create"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<Export.Create />} />
          }
        />

        <Route
          path="/export/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN, ROLES.CONTENT_MANAGER]} page={<Export.Update />} />
          }
        />

        <Route
          path="/media"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Media.List />} />
          }
        />

        <Route
          path="/media/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Media.Create />} />
          }
        />

        <Route
          path="/media/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Media.Update />} />
          }
        />

        <Route
          path="/business-article"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BusinessArticle.List />} />
          }
        />

        <Route
          path="/business-article/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BusinessArticle.Create />} />
          }
        />

        <Route
          path="/business-article/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BusinessArticle.Update />} />
          }
        />

        <Route
          path="/business-article-type"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BusinessArticleType.List />} />
          }
        />

        <Route
          path="/business-article-type/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BusinessArticleType.Create />} />
          }
        />

        <Route
          path="/business-article-type/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BusinessArticleType.Update />} />
          }
        />

        <Route
          path="/video"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Video.List />} />
          }
        />

        <Route
          path="/video/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Video.Create />} />
          }
        />

        <Route
          path="/video/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Video.Update />} />
          }
        />

        <Route
          path="/info-pages"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<InfoPages />} />
          }
        />

        <Route
          path="/banner"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Banner.List />} />
          }
        />

        <Route
          path="/banner/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Banner.Create />} />
          }
        />

        <Route
          path="/banner/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Banner.Update />} />
          }
        />

        <Route
          path="/comment"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Comment.List />} />
          }
        />

        <Route
          path="/best-comment"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BestComment.List />} />
          }
        />

        <Route
          path="/best-comment/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BestComment.Create />} />
          }
        />

        <Route
          path="/best-comment/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<BestComment.Update />} />
          }
        />

        <Route
          path="/useful"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Useful.List />} />
          }
        />

        <Route
          path="/useful/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Useful.Create />} />
          }
        />

        <Route
          path="/useful/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Useful.Update />} />
          }
        />

        <Route
          path="/network"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Network.List />} />
          }
        />

        <Route
          path="/network/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Network.Create />} />
          }
        />

        <Route
          path="/network/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Network.Update />} />
          }
        />

        <Route
          path="/translation"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Translation.List />} />
          }
        />

        <Route
          path="/translation/create"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Translation.Create />} />
          }
        />

        <Route
          path="/translation/update/:id"
          element={
            <RequireRole roles={[ROLES.ADMIN]} page={<Translation.Update />} />
          }
        />

        <Route path='*' element={<Navigate to='/call' />} />
      </Routes>
    </Layout.Main>
  );
}

export default App;
