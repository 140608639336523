import React from 'react';

const Key = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 56">
    <path
      fill="#2678F4"
      d="M46.177 9.847c-6.907-6.884-18.107-6.884-24.967 0-4.83 4.783-6.277 11.666-4.41 17.733L5.833 38.547c-.77.793-1.306 2.356-1.143 3.476l.7 5.087c.257 1.68 1.82 3.267 3.5 3.5l5.087.7c1.12.163 2.683-.35 3.476-1.167l1.914-1.913c.466-.443.466-1.19 0-1.657l-4.527-4.526a1.76 1.76 0 0 1 0-2.474 1.76 1.76 0 0 1 2.473 0l4.55 4.55a1.17 1.17 0 0 0 1.634 0l4.946-4.923a17.674 17.674 0 0 0 17.734-4.363c6.883-6.884 6.883-18.107 0-24.99ZM33.833 28A5.835 5.835 0 0 1 28 22.167a5.835 5.835 0 0 1 5.833-5.834 5.836 5.836 0 0 1 5.834 5.834A5.835 5.835 0 0 1 33.833 28Z"
      opacity=".4"
    />
    <path fill="#0386FF" d="M33.833 28a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.667Z" />
  </svg>
);

export default Key;
