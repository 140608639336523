export const ENTITY = 'MEDIA';

export const TYPE = {
  MEDIATEKA: 'MEDIATEKA',
  GET_INFO: 'GET_INFO'
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}
