import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import TextareaBase from "./Base/Textarea";

const Textarea = ({ title, name, required }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(title)} {...{ required }} />
      <TextareaBase
        name={name}
        placeholder={`${title}_enter`}
      />
    </>
  )
}

export default Textarea;