import get from 'lodash/get';
import moment from "moment";

export const BlogForOurService = (item) => {
  const createdAt = moment(get(item, 'createdAt'), 'DD.MM.YYYY HH:mm:SS');

  return {
    id: get(item, 'id') || '',
    info: {
      uz: get(item, 'info.uz') || '',
      oz: get(item, 'info.oz') || '',
      ru: get(item, 'info.ru') || '',
      en: get(item, 'info.en') || '',
    },
    reqDocs: {
      uz: get(item, 'reqDocs.uz') || '',
      oz: get(item, 'reqDocs.oz') || '',
      ru: get(item, 'reqDocs.ru') || '',
      en: get(item, 'reqDocs.en') || '',
    },
    ourServiceParentId: get(item, 'ourServiceParentId') || '',
    ourServiceId: get(item, 'ourServiceId') || '',
    url: get(item, 'url') || '',
    createdAt: get(item, 'createdAt') && createdAt.isValid() ? createdAt.format('DD.MM.YYYY') : '',
    status: get(item, 'status') || ''
  }
};

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalCount') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 10
});