import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classes from './Profile.module.scss';

const Profile = () => {
  const { i18n } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Link to='/call'>
        <img src={require('assets/images/profile.png')} alt="Logo" width={60} height={60} />
      </Link>

      <select
        onChange={e => i18n.changeLanguage(e.target.value)}
        className={classes.select}
        value={i18n.language}
      >
        <option value="uz">O'zbekcha</option>
        <option value="oz">Ўзбекча</option>
        <option value="ru">Русский</option>
        <option value="en">English</option>
      </select>
    </div>
  )
};

export default Profile;