import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from "react-i18next";

import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from "../constants";

const Update = ({ id, values, onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Update({ id, values });

      return Mappers.Call(data && data.data);
    },
      {
        onSuccess: () => {
          onSuccess();
          queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === Constants.ENTITY && query.queryKey[1] === 'list'
          });
        },
        onError,
        onSettled
      }
  );

  const validationSchema = yup.object().shape({
    callingStatus: yup.string().required(t('required'))
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        callingStatus: values.callingStatus
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Update;
