import React from 'react';
import { useTranslation } from "react-i18next";

import { STATUS } from 'modules/call/constants';

import * as Filter from 'containers/Filter';
import Dropdown from "containers/Dropdown";

const FilterList = ({ params, setParams }) => {
  const { t } = useTranslation();

  const STATUS_LIST = {
    ALL: t('all'),
    [STATUS.WAITING]: t('status_enum_WAITING'),
    [STATUS.CALLED]: t('status_enum_CALLED'),
    [STATUS.FINISHED]: t('status_enum_FINISHED')
  };

  const setParamValue = (name, value) => {
    setParams({ ...params, [name]: value || undefined, page: undefined });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
      <Dropdown
        name='name'
        value={params.name || ''}
        onCancel={() => setParamValue('name', '')}
      >
        <Filter.Input
          value={params.name || ''}
          setValue={value => setParamValue('name', value)}
        />
      </Dropdown>

      <Dropdown
        name='phone'
        value={params.phone || ''}
        onCancel={() => setParamValue('phone', '')}
      >
        <Filter.Input
          value={params.phone || ''}
          setValue={value => setParamValue('phone', value)}
        />
      </Dropdown>

      <Dropdown
        name='calling_status'
        value={params.callingStatus || ''}
        onCancel={() => setParamValue('callingStatus', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.WAITING, title: STATUS_LIST[STATUS.WAITING] },
            { value: STATUS.CALLED, title: STATUS_LIST[STATUS.CALLED] },
            { value: STATUS.FINISHED, title: STATUS_LIST[STATUS.FINISHED] }
          ]}
          value={params.callingStatus || ''}
          setValue={value => setParamValue('callingStatus', value)}
        />
      </Dropdown>
    </div>
  )
}

export default FilterList;