import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/banner/list-banner', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/banner/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/banner', {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    bannerType: values.bannerType,
    fileId: values.photoId,
    url: values.url,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/banner/${id}`, {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    bannerType: values.bannerType,
    fileId: values.photoId,
    url: values.url,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/banner/${id}`);