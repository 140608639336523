import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import * as Icons from 'assets/icons';

import InputBase from 'components/Input';

import cls from './Filter.module.scss';

const Input = ({ value, setValue }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        setValue(inputValue);
      }}
    >
      <InputBase
        size='sm'
        value={inputValue}
        placeholder={t('what_to_search')}
        onChange={value => setInputValue(value)}
        iconSuffix={
          (value || inputValue) && (
            <div
              className={cls.icon}
              onClick={() => {
                setInputValue('');
                setValue('');
              }}
            >
              <Icons.Close />
            </div>
          )
        }
        autoFocus
      />
    </form>
  )
}

export default Input;