import React from 'react';

import Icon from '../Icon';

import classes from './Uploader.module.scss';

const Progress = ({ type, percent, total, loaded, onCancel }) => {
  const title = {
    image: 'Идет загрузка фото...',
    video: 'Идет загрузка видео...',
    file: 'Идет загрузка файла...'
  };

  return (
    <div className={classes.progress}>
      <div className={classes.progressHeader}>
        <div className={classes.progressTitle}>{title[type]}</div>
        <div className={classes.progressPercent}>{percent}%</div>
        <div className={classes.progressCancel} onClick={() => onCancel()}>
          <Icon type="fluent" variant="filled" name="DismissCircle" />
        </div>
      </div>
      <div className={classes.progressBar}>
        <div className={classes.progressBarInner} style={{ width: `${percent}%` }} />
      </div>
      <div className={classes.progressInfo}>
        Загружено: {loaded} кб из {total} кб
      </div>
    </div>
  );
};

export default Progress;
