import React, { useRef } from 'react';
import { Editor as BaseEditor } from '@tinymce/tinymce-react';
import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';

import './Editor.scss';
import classes from './Editor.module.scss';

const Editor = ({
  id,
  placeholder,
  disabled,
  state,
  value,
  apiKey,
  message,
  onChange,
  imagesUploadHandler,
  minHeight = 250,
  ...props
}) => {
  const ref = useRef(null);
  const wrapperRef = useRef(null);

  const editorId = kebabCase(id);

  return (
    <div
      ref={wrapperRef}
      className={cx(
        classes.wrapper,
        state && classes[`wrapper--state-${state}`],
        disabled && classes['wrapper--disabled']
      )}
    >
      <div className={classes.editor}>
        <BaseEditor
          ref={ref}
          {...{ props }}
          {...{ id: editorId, apiKey, placeholder, disabled }}
          onInit={(evt, editor) => {
            ref.current = editor;
          }}
          onEditorChange={(newValue) => onChange && onChange(newValue || '')}
          value={value || ''}
          onFocus={() => {
            wrapperRef?.current && wrapperRef?.current?.classList.add(classes[`wrapper--focused`]);
          }}
          onBlur={() => {
            wrapperRef?.current && wrapperRef?.current?.classList.remove(classes[`wrapper--focused`]);
          }}
          init={{
            min_height: minHeight,
            readonly: disabled,
            placeholder,
            statusbar: false,
            menubar: false,
            image_title: true,
            automatic_uploads: true,
            plugins: [
              'autoresize',
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            file_picker_types: 'image',
            font_size_style_values:'Islombek',
            toolbar:
              ' undo redo | formatselect | ' +
              'bold italic underline blockquote forecolor backcolor | alignleft aligncenter ' +
              'alignright alignjustify | link image media | bullist numlist outdent indent | ' +
              'removeformat | table | code',
            images_upload_handler: imagesUploadHandler
          }}
        />
      </div>
      {!!message && <div className={classes.message}>{message}</div>}
    </div>
  );
};

export default Editor;
