import React from 'react';

import { STATUS } from "modules/call/constants";
import * as Fields from 'containers/Fields';

const Form = () => (
  <Fields.Select
    title='calling_status'
    name="callingStatus"
    options={[
      { value: STATUS.WAITING, title: 'status_enum_WAITING' },
      { value: STATUS.CALLED, title: 'status_enum_CALLED' },
      { value: STATUS.FINISHED, title: 'status_enum_FINISHED' }
    ]}
  />
);

export default Form;