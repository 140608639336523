import React from 'react';

import { STATUS } from "modules/comment/constants";
import * as Fields from 'containers/Fields';

const Form = () => (
  <Fields.Select
    title='status'
    name="status"
    options={[
      { value: STATUS.ACTIVE, title: 'status_enum_ACTIVE' },
      { value: STATUS.INACTIVE, title: 'status_enum_INACTIVE' },
      { value: STATUS.DELETED, title: 'status_enum_DELETED' }
    ]}
  />
);

export default Form;