import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useSingle = ({ id }) => {
    const initialData = { item: Mappers.Banner() };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'single', id],
    async () => {
      const { data } = await Api.Single({ id });

      return {
        item: Mappers.Banner(data?.data)
      };
    },
    { initialData }
  );

  return { ...args, ...data };
};

export default useSingle;
