import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/advice/list-advice', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const ParentList = () => http.request.get('/api/v1/admin/advice/list/advice');

export const ChildList = ({ parentId }) => http.request.get(`/api/v1/admin/advice/list/children/${parentId}`);

export const Single = ({ id }) => http.request.get(`/api/v1/admin/advice/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/advice', {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    parentId: values.parentId || null,
    fileId: values.photoId,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/advice/${id}`, {
    name: {
      uz: values.name.uz,
      oz: values.name.oz,
      ru: values.name.ru,
      en: values.name.en
    },
    parentId: values.parentId || null,
    fileId: values.photoId,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/advice/${id}`);