import React, { useState } from 'react';
import cx from 'classnames';

import classes from './Input.module.scss';

const Input = ({
  value,
  name,
  size = 'md',
  type = 'text',
  state = 'default', // 'default' | 'success' | 'error'
  placeholder,
  message,
  disabled,
  iconPrefix,
  iconSuffix,
  autoFocus,
  onChange,
  onBlur
}) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <>
      <div className={classes.inner}>
        {!!iconPrefix && <div className={cx(classes.icon, classes.iconPrefix)}>{iconPrefix}</div>}

        <input
          className={cx(
            classes.input,
            classes[`input--size-${size}`],
            state && classes[`input--state-${state}`],
            isFocused && classes['input--focused'],
            iconPrefix && classes['input--has-iconPrefix'],
            iconSuffix && classes['input--has-iconSuffix']
          )}
          {...{ value, type, name, placeholder, disabled, autoFocus }}
          onChange={e => onChange && onChange(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={e => {
            setFocused(false);
            onBlur && onBlur(e);
          }}
          autoComplete="off"
        />

        {!!iconSuffix && <div className={cx(classes.icon, classes.iconSuffix)}>{iconSuffix}</div>}
      </div>

      {!!message && <div className={classes.message}>{message}</div>}
    </>
  );
};

export default Input;
