import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/network/list', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/network/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/network', {
    url: values.url,
    fileId: values.photoId,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/network/${id}`, {
    url: values.url,
    fileId: values.photoId,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/network/${id}`);