export const ENTITY = 'USEFUL';

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

export const TYPE = {
  RESOURCE: 'RESOURCE',
  ABOUT_PORTAL: 'ABOUT_PORTAL'
}