import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-grid-system';

import * as Hooks from 'modules/article/hooks';

import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = ({ values }) => {
  const { i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState('uz');

  const parentArticles = Hooks.useParentList();

  const childArticles = Hooks.useChildList( {
    parentId: values.articleParentId
  });

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['info', 'reqDocs']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Input
                required
                title='info'
                name={`info[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Editor
                required
                title='req_docs'
                name={`reqDocs[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                required
                title='article_parent'
                name='articleParentId'
                options={[
                  { value: '', title: 'select_article' },
                  ...parentArticles.items.map(item => ({ value: item.id, title: item.name[i18n.language]}))
                ]}
              />
            </Col>
            {values.articleParentId && (
              <Col sm={12}>
                <Fields.Select
                  required
                  title='child_article'
                  name='articleId'
                  options={[
                    { value: '', title: 'select_article' },
                    ...childArticles.items.map(item => ({ value: item.id, title: item.name[i18n.language]}))
                  ]}
                />
              </Col>
            )}
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;