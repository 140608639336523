import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';

import * as List from './list';

import classes from './Icon.module.scss';

const Icon = ({ name, type = 'fluent', variant = 'regular', size, className, color, onClick }) => {
  const Component = get(List, `[${type}][${variant}][${name}]`);

  if (!Component) {
    console.log('Icon component not found');

    return null;
  }

  return (
    <div
      className={cx(classes.wrapper, className)}
      {...{ onClick }}
      style={{ ...(size ? { '--size': `${size}px` } : {}), color }}
    >
      <Component />
    </div>
  );
};

export default Icon;
