export const ENTITY = 'BLOG_FOR_ADVICE';

export const TYPE = 'ADVICE';

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

export const CATEGORY_INVESTOR = {
  MAIN: 'MAIN',
  LEGISLATION: 'LEGISLATION',
  CONDITION_TERRITORIES: 'CONDITION_TERRITORIES'
}