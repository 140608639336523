import React from 'react';
import { NavLink } from "react-router-dom";

import * as Hooks from "modules/auth/hooks";

import Profile from "components/Profile";

import { menus } from "./menus";
import './Sidebar.scss';
import classes from './Sidebar.module.scss';

const Sidebar = () => {
  const { profile } = Hooks.useAuth();

  return (
    <div className={classes.wrapper}>
      <div className={classes.profile}>
        <Profile />
      </div>

      <ul className={classes.menu}>
        {menus.map(menu => {
          if (!menu.roles.includes(profile.role)) {
            return null;
          }

          return (
            <li key={menu.path}>
              <NavLink className={classes.link} to={menu.path}>
                {menu.name}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
};

export default Sidebar;