import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-grid-system';

import * as Hooks from 'modules/statisticCategory/hooks';
import * as Fields from 'containers/Fields';
import { COLORS } from 'modules/statistic/constants';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const { i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState('uz');

  const { items } = Hooks.useListAll();

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['title']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Fields.Editor
            required
            title='title'
            name={`title[${activeLanguage}]`}
          />
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Select
                required
                title='select_category'
                name="categoryId"
                options={[
                  { value: '', title: 'select_category' },
                  ...items.map(item => ({ value: item.id, title: item.statisticName[i18n.language] }))
                ]}
              />
            </Col>
            <Col sm={12}>
              <Fields.Input
                required
                type='number'
                title='rating'
                name="rating"
              />
            </Col>
            <Col sm={12}>
              <Fields.Select
                required
                title='color'
                name="color"
                options={[
                  { value: '', title: 'select_color' },
                  { value: COLORS.RED, title: 'color_enum_RED' },
                  { value: COLORS.PINK, title: 'color_enum_PINK' },
                  { value: COLORS.BLUE, title: 'color_enum_BLUE' },
                  { value: COLORS.GREEN, title: 'color_enum_GREEN' },
                  { value: COLORS.PURPLE, title: 'color_enum_PURPLE' },
                  { value: COLORS.WARNING, title: 'color_enum_WARNING' },
                  { value: COLORS.TURQUOISE, title: 'color_enum_TURQUOISE' },
                  { value: COLORS.LIGHTPURPLE, title: 'color_enum_LIGHTPURPLE' },
                ]}
              />
            </Col>
            <Col sm={12}>
              <Fields.Input
                required
                type='number'
                title='sort'
                name="sort"
              />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;