import get from 'lodash/get';
import moment from "moment";

export const Faq = (item) => {
  const createdAt = moment(get(item, 'createdAt'), 'DD.MM.YYYY HH:mm:SS');

  return {
    id: get(item, 'id') || '',
    question: {
      uz: get(item, 'question.uz') || '',
      oz: get(item, 'question.oz') || '',
      ru: get(item, 'question.ru') || '',
      en: get(item, 'question.en') || '',
    },
    info: {
      uz: get(item, 'blogResponse.info.uz') || '',
      oz: get(item, 'blogResponse.info.oz') || '',
      ru: get(item, 'blogResponse.info.ru') || '',
      en: get(item, 'blogResponse.info.en') || '',
    },
    reqDocs: {
      uz: get(item, 'blogResponse.reqDocs.uz') || '',
      oz: get(item, 'blogResponse.reqDocs.oz') || '',
      ru: get(item, 'blogResponse.reqDocs.ru') || '',
      en: get(item, 'blogResponse.reqDocs.en') || '',
    },
    createdAt: get(item, 'createdAt') && createdAt.isValid() ? createdAt.format('DD.MM.YYYY') : '',
    status: get(item, 'status') || ''
  }
};

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalCount') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 10
});