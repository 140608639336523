import React from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';

import Content from "components/Content";

const Form = () => (
  <Row>
    <Col sm={8}>
      <Content>
        <Fields.Input
          required
          title='url'
          name='url'
        />
      </Content>
    </Col>

    <Col sm={4}>
      <Content>
        <Fields.Status />
      </Content>
    </Col>
  </Row>
);

export default Form;