import { ROLES } from 'modules/auth/constants';

export const menus = [
  {
    path: '/call',
    name: 'Call',
    roles: [ROLES.ADMIN, ROLES.CALL_MANAGER]
  },
  {
    path: '/faq',
    name: 'Faq',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/advice',
    name: 'Advice',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/blog-for-advice',
    name: 'Blog for Advice',
    roles: [ROLES.ADMIN, ROLES.CONTENT_MANAGER]
  },
  {
    path: '/article',
    name: 'Article',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/blog-for-article',
    name: 'Blog for Article',
    roles: [ROLES.ADMIN, ROLES.CONTENT_MANAGER]
  },
  {
    path: '/our-service',
    name: 'Our service',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/blog-for-our-service',
    name: 'Blog for Our service',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/hotline',
    name: 'Hotline',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/expert',
    name: 'Expert',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/new',
    name: 'New',
    roles: [ROLES.ADMIN, ROLES.CONTENT_MANAGER]
  },
  {
    path: '/statistic',
    name: 'Statistic',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/export',
    name: 'Export',
    roles: [ROLES.ADMIN, ROLES.CONTENT_MANAGER]
  },
  {
    path: '/media',
    name: 'Media',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/business-article',
    name: 'Business Article',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/video',
    name: 'Video',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/info-pages',
    name: 'Info pages',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/banner',
    name: 'Banner',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/comment',
    name: 'Comment',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/best-comment',
    name: 'Best comment',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/useful',
    name: 'Useful',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/network',
    name: 'Network',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/user',
    name: 'User',
    roles: [ROLES.ADMIN]
  },
  {
    path: '/translation',
    name: 'Translation',
    roles: [ROLES.ADMIN]
  }
];