import { http } from 'services';
import { TYPE } from './constants';

export const List = ({ params }) => http.request.post('/api/v1/admin/blog/list/type-for-admin', {
    page: params.page,
    per_page: params.perPage,
    search: params.filter
  }, {
  params: {
    type: TYPE
  }
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/blog/${id}`);

export const Create = ({ values }) => {
  const data = {
    info: {
      uz: values.info.uz,
      oz: values.info.oz,
      ru: values.info.ru,
      en: values.info.en
    },
    reqDocs: {
      uz: values.reqDocs.uz,
      oz: values.reqDocs.oz,
      ru: values.reqDocs.ru,
      en: values.reqDocs.en
    },
    showCarousel: values.showCarousel,
    investor: values.showInvestor,
    type: TYPE,
    status: values.status
  }

  if (values.showCarousel) {
    data.titleCarousel = {
      uz: values.titleCarousel.uz,
      oz: values.titleCarousel.oz,
      ru: values.titleCarousel.ru,
      en: values.titleCarousel.en
    };
    data.iconId = values.iconId;
  }

  if (values.showInvestor) {
    data.titleInvestor = {
      uz: values.titleInvestor.uz,
      oz: values.titleInvestor.oz,
      ru: values.titleInvestor.ru,
      en: values.titleInvestor.en
    };
    data.categoryInvestor = values.categoryInvestor;
    data.photoId = values.photoId;
  }

  return http.request.post(`/api/v1/admin/blog/blog-for-advice/${values.adviceId}`, data);
}

export const Update = ({ id, values }) => {
  const data = {
    info: {
      uz: values.info.uz,
      oz: values.info.oz,
      ru: values.info.ru,
      en: values.info.en
    },
    reqDocs: {
      uz: values.reqDocs.uz,
      oz: values.reqDocs.oz,
      ru: values.reqDocs.ru,
      en: values.reqDocs.en
    },
    adviceId: values.adviceId,
    showCarousel: values.showCarousel,
    investor: values.showInvestor,
    status: values.status
  }

  if (values.showCarousel) {
    data.titleCarousel = {
      uz: values.titleCarousel.uz,
      oz: values.titleCarousel.oz,
      ru: values.titleCarousel.ru,
      en: values.titleCarousel.en
    };
    data.iconId = values.iconId;
  }

  if (values.showInvestor) {
    data.titleInvestor = {
      uz: values.titleInvestor.uz,
      oz: values.titleInvestor.oz,
      ru: values.titleInvestor.ru,
      en: values.titleInvestor.en
    };
    data.categoryInvestor = values.categoryInvestor;
    data.photoId = values.photoId;
  }

  return http.request.put(`/api/v1/admin/blog/advice/${id}`, data);
}

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/blog/${id}`);