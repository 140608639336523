import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/translations/list', {
    page: params.page,
    per_page: params.perPage,
    search: params.filter
});

export const Single = ({ id }) => http.request.get(`/api/v1/admin/translations/${id}`);

export const Create = ({ values }) => http.request.post('/api/v1/admin/translations', {
    nameUz: values.name.uz,
    nameOz: values.name.oz,
    nameRu: values.name.ru,
    nameEn: values.name.en,
    types: ['FRONT_OFFICE'],
    tag: values.tag,
    status: values.status
  }
);

export const Update = ({ id, values }) => http.request.put(`/api/v1/admin/translations/${id}`, {
    nameUz: values.name.uz,
    nameOz: values.name.oz,
    nameRu: values.name.ru,
    nameEn: values.name.en,
    types: ['FRONT_OFFICE'],
    tag: values.tag,
    status: values.status
  }
);

export const Delete = ({ id }) => http.request.delete(`/api/v1/admin/translations/${id}`);