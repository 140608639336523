import get from 'lodash/get';
import { useQuery } from 'react-query';

import config from "config";

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useList = ({ params }) => {
  const initialData = { items: [], meta: Mappers.Meta() };

  const paramsWithDefault = {
    page: params?.page ? params.page - 1 : 0,
    perPage: params?.perPage || config.list.perPage,
    filter: (params?.filter || []).filter(i => !!i.value)
  };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'list', paramsWithDefault],
    async () => {
      const { data } = await Api.List({
        params: paramsWithDefault
      });

      const items = (get(data, 'data') || []).map(item => Mappers.Faq(item));

      const meta = Mappers.Meta(data);

      return { items, meta };
    },
    { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useList;
