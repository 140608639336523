import React from 'react';

const ArchiveBook = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 5.83334V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V5.83334C2.5 3.33334 3.75 1.66667 6.66667 1.66667H13.3333C16.25 1.66667 17.5 3.33334 17.5 5.83334Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M12.9167 1.66667V8.21666C12.9167 8.58333 12.4833 8.76666 12.2166 8.52499L10.2834 6.74169C10.125 6.59169 9.87498 6.59169 9.71664 6.74169L7.78335 8.52499C7.51669 8.76666 7.08333 8.58333 7.08333 8.21666V1.66667H12.9167Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M11.0417 11.6667H14.5833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M7.5 15H14.5833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArchiveBook;
