import get from 'lodash/get';

import * as Constants from './constants';

export const File = (item) => {
  const type = get((get(item, 'type') || '').split('/'), '[0]') || '';

  const types = {
    image: Constants.TYPE.IMAGE,
    video: Constants.TYPE.VIDEO,
    audio: Constants.TYPE.AUDIO
  };

  return {
    id: get(item, 'id') || '',
    name: get(item, 'name') || '',
    url: get(item, 'uploadPath') || '',
    size: SizeFormat(get(item, 'size') || 0),
    type: types[type] || Constants.TYPE.FILE,
    extension: get(item, 'extension') || ''
  };
};

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalCount') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 10
});

export const SizeFormat = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
