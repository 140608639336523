import { http } from 'services';

export const List = ({ params }) => http.request.post('/api/v1/admin/user/list', {
  page: params.page,
  per_page: params.perPage,
  search: params.filter
});

export const Update = ({ values }) => http.request.post('/api/v1/admin/user/change-role', {
    userId: values.id,
    roleName: values.roleName
  }
);