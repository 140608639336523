import React from 'react';
import { useField } from 'formik';

import SelectBase from 'components/Select';

const Select = ({ name, onChange, ...props }) => {
  const [field, meta, helper] = useField({ name });
  const hasError = !!(meta.error && meta.touched);

  return (
    <SelectBase
      {...field}
      {...props}
      value={field.value || ''}
      message={hasError ? meta.error : ''}
      state={hasError ? 'error' : 'default'}
      onChange={value => helper.setValue(value)}
    />
  );
};

export default Select;
