import React from 'react';
import cx from "classnames";

import cls from './Badge.module.scss';

const Badge = ({ status }) => (
  <div className={cx(cls.badge, cls[`badge--status-${status}`])}>
    {status}
  </div>
);

export default Badge;