import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import SelectBase from "./Base/Select";

const Select = ({ title, name, required, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(title)} {...{ required }} />
      <SelectBase
        name={name}
        {...props}
      />
    </>
  )
};

export default Select;