const ENV = process.env;

const config = {
  sso: {
    link: ENV.REACT_APP_SSO_LINK
  },
  api: {
    base_url: ENV.REACT_APP_SERVER_URL
  },
  list: {
    perPage: 10
  },
  pattern: {
    phone: /^[+]998(9[012345789]|6[125679]|7[01234569])[0-9]{7}$/
  }
};

export default config;


