import React from 'react';
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/advice/hooks';
import { STATUS } from 'modules/blogForAdvice/constants';

import * as Filter from 'containers/Filter';
import Dropdown from "containers/Dropdown";

const FilterList = ({ params, setParams }) => {
  const { t, i18n } = useTranslation();

  const { items } = Hooks.useParentList();

  const STATUS_LIST = {
    ALL: t('all'),
    [STATUS.ACTIVE]: t('status_enum_ACTIVE'),
    [STATUS.INACTIVE]: t('status_enum_INACTIVE')
  };

  const setParamValue = (name, value) => {
    setParams({ ...params, [name]: value || undefined, page: undefined });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
      <Dropdown
        name='info'
        value={params.info || ''}
        onCancel={() => setParamValue('info', '')}
      >
        <Filter.Input
          value={params.info || ''}
          setValue={value => setParamValue('info', value)}
        />
      </Dropdown>

      <Dropdown
        name='status'
        value={params.status || ''}
        onCancel={() => setParamValue('status', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.ACTIVE, title: STATUS_LIST[STATUS.ACTIVE] },
            { value: STATUS.INACTIVE, title: STATUS_LIST[STATUS.INACTIVE] }
          ]}
          value={params.status || ''}
          setValue={value => setParamValue('status', value)}
        />
      </Dropdown>

      <Dropdown
        name='show_carousel'
        value={params.showCarousel || ''}
        onCancel={() => setParamValue('showCarousel', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.ACTIVE, title: STATUS_LIST[STATUS.ACTIVE] },
            { value: STATUS.INACTIVE, title: STATUS_LIST[STATUS.INACTIVE] }
          ]}
          value={params.showCarousel || ''}
          setValue={value => setParamValue('showCarousel', value)}
        />
      </Dropdown>

      <Dropdown
        name='status_investor'
        value={params.showInvestor || ''}
        onCancel={() => setParamValue('showInvestor', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            { value: STATUS.ACTIVE, title: STATUS_LIST[STATUS.ACTIVE] },
            { value: STATUS.INACTIVE, title: STATUS_LIST[STATUS.INACTIVE] }
          ]}
          value={params.showInvestor || ''}
          setValue={value => setParamValue('showInvestor', value)}
        />
      </Dropdown>

      <Dropdown
        name='advice_parent'
        value={params.adviceParentId || ''}
        onCancel={() => setParamValue('adviceParentId', '')}
      >
        <Filter.Select
          options={[
            { value: '', title: STATUS_LIST.ALL },
            ...items.map(item => ({ value: item.id, title: item.name[i18n.language]}))
          ]}
          value={params.adviceParentId || ''}
          setValue={value => setParamValue('adviceParentId', value)}
        />
      </Dropdown>
    </div>
  )
}

export default FilterList;