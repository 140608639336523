import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as Forms from 'modules/infoPages/forms';
import * as Hooks from 'modules/infoPages/hooks';
import { PAGE } from 'modules/infoPages/constants';

import TopBar from "components/TopBar";
import Title from "components/Title";
import Button from "components/Button";
import Spinner from 'containers/Spinner';
import Form from './components/Form';

const InfoPages = () => {
  const { t } = useTranslation();
  const [type, setType] = useState(PAGE.ABOUT_US);

  const { item, isFetched } = Hooks.useSingle({ type });

  return (
    <Forms.InfoPage
      values={item}
      onSuccess={() => {
        toast.success('successfully_updated');
      }}
    >
      {({ values, isSubmitting }) => {
        setType(values.pageName);

        return (
          <>
            <Spinner isLoading={!isFetched || isSubmitting} />

            <TopBar
              left={<Title text={t('info_pages_title')} />}
              right={<Button variant='success' title={t('action_save')} type='submit' />}
            />

            <Form />
          </>
        )
      }}
    </Forms.InfoPage>
  );
};

export default InfoPages;
