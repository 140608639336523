import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useUrlSearchParams } from "use-url-search-params";

import * as Hooks from "modules/article/hooks";
import * as Helpers from "helpers";

import TopBar from "components/TopBar";
import Content from "components/Content";
import Button from "components/Button";
import Title from "components/Title";
import Badge from "components/Badge";
import Empty from "components/Empty";
import Modal from "components/Modal";
import Spacer from "components/Spacer";
import Table from "containers/Table";
import Spinner from "components/Spinner";
import Pagination from "containers/Pagination";
import FilterList from "./components/FilterList";
import Delete from "./components/Delete";

const List = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useUrlSearchParams();
  const [selected, setSelected] = useState('');

  const { items, meta, isFetched } = Hooks.useList({
    params: {
      page: params.page || 1,
      filter: [
        {
          key: `name.${i18n.language}`,
          operation: '=',
          value: params.name
        },
        {
          key: 'status',
          operation: '=',
          value: params.status
        },
        {
          key: 'parentId',
          operation: '=',
          value: params.parentId
        }
      ]
    }
  });

  return (
    <>
      <TopBar
        left={<Title text={t('article_title')} />}
        right={
          <Button
            onClick={() => navigate('/article/create')}
            variant='secondary'
            title={t('add_btn')}
          />
        }
      />

      <FilterList {...{ params, setParams }} />

      <Spacer size={20} />

      <Content>
        { !isFetched ? (
            <div style={{ position: 'relative', padding: 200 }}>
              <Spinner />
            </div>
          ) : !items.length ? (
            <Empty
              title={t('list_empty_here')}
              description={t('list_will_appear_soon')}
            />
          ) : (
            <>
              <Table
                items={items}
                columns={[
                  {
                    name: '№',
                    selector: row => row.id,
                    width: '80px'
                  },
                  {
                    name: t('name'),
                    selector: row => row.name[i18n.language]
                  },
                  {
                    name: t('image'),
                    selector: row => row.photoId ?  <img src={Helpers.Image.Url(row.photoId)} style={{ objectFit: 'cover' }} width={45} height={45} /> : t('image_not_upload')
                  },
                  {
                    name: t('created_at'),
                    selector: row => row.createdAt
                  },
                  {
                    name: t('status'),
                    selector: row => <Badge status={row.status} />
                  }
                ]}
                onEdit={item => navigate(`/article/update/${item.id}`)}
                onDelete={item => setSelected(item.id)}
              />

              <Spacer size={20} />

              <Pagination
                current={meta.current}
                total={meta.totalPages}
                currentItems={items.length}
                totalItems={meta.totalItems}
                {...{ params, setParams }}
              />
            </>
          )
        }
      </Content>

      <Modal visible={!!selected} onCancel={() => setSelected('')}>
        <Delete id={selected} onCancel={() => setSelected('')} />
      </Modal>
    </>
  )
};

export default List;