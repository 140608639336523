import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";

import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Update = ({ id, values, onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Update({ id, values });

      return Mappers.Banner(data && data.data);
    },
      {
        onSuccess,
        onError,
        onSettled
      }
  );

  const validationSchema = yup.object().shape({
    name: yup.object().shape({
      uz: yup.string().required(t('required')),
      oz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    bannerType: yup.string().required(t('required')),
    photoId: yup.string().required(t('required')),
    url: yup.string().required(t('required'))
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        name: {
          uz: values.name.uz,
          oz: values.name.oz,
          ru: values.name.ru,
          en: values.name.en
        },
        bannerType: values.bannerType,
        photoId: values.photoId,
        url: values.url,
        status: values.status
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Update;
