import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useChildList = ({ parentId }) => {
  const initialData = { items: [] };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'child', 'list', parentId],
    async () => {
      const { data } = await Api.ChildList({ parentId });

      const items = (data || []).map(item => Mappers.Advice(item));

      return { items };
    },
    { initialData, enabled: !!parentId, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useChildList;
