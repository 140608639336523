import React from 'react';
import { toast } from "react-toastify";

import * as Hooks from 'modules/network/hooks';

import ConfirmDelete from 'containers/ConfirmDelete';

const Delete = ({ id, onCancel }) => {
  const { mutate, isLoading } = Hooks.useDelete();

  const Confirm = () => {
    mutate({ id }, {
        onSuccess: () => {
          onCancel();
          toast.success('successfully_deleted');
        }
      }
    );
  };

  return <ConfirmDelete {...{ isLoading, Confirm, onCancel }} />;
};

export default Delete;