import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import { PAGE } from 'modules/infoPages/constants';
import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('uz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['info', 'reqDocs']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Input
                required
                title='info'
                name={`info[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Editor
                required
                title='req_docs'
                name={`reqDocs[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Fields.Select
            required
            title='page_name'
            name="pageName"
            options={[
              { value: PAGE.ABOUT_US, title: 'page_enum_ABOUT_US' },
              { value: PAGE.ADVICE_FOR_BUSINESS, title: 'page_enum_ADVICE_FOR_BUSINESS' },
              { value: PAGE.HOW_WORK, title: 'page_enum_HOW_WORK' }
            ]}
          />
        </Content>
      </Col>
    </Row>
  )
};

export default Form;